<template>
    <div>
        <div v-if="selectedCountry === 1">
            <div>
                This device is not intended to diagnose or treat any medical condition or disease. The Sway Balance™ System is designed to provide a portable screening tool for balance assessment and in no way should it replace an evaluation by a medical professional. Balance is a common symptom of many acute and chronic diseases, conditions, reactions and injuries, however, if you suspect you have compromised balance, especially following a potential brain injury, you should seek medical attention immediately.
            </div>
            <div style="margin-top: 10px">
                Attempting a balance test with suspected balance impairment increases the risk for fall injury and should not be performed without supervision. Do not perform a balance test if you have been injured without first seeking medical attention.
            </div>
        </div>
        <div v-else>
            This device is not intended to diagnose or treat any medical condition or disease. The Sway Balance™ System is designed to provide a portable screening tool for balance assessment and in no way should it replace an evaluation by a medical professional. Impaired balance and reaction time are common symptoms of many acute and chronic diseases, conditions, reactions and injuries, however, if you suspect you have compromised balance or reaction time, especially following a potential brain injury, you should seek medical attention immediately.
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutWarnings",
    props: {
        selectedCountry: {
            required: true
        }
    }
}
</script>

<style scoped>

</style>