<template>
    <div>
        <div
            v-for="(section, sectionIndex) in sections"
            :key="sectionIndex"
            style="margin-bottom: 30px; margin-top: 20px"
        >


            <div class="section-title animate" v-html="section.title">
                <!-- {{section.title}} -->
            </div>
            <div class="section-description animate" v-html="section.description">
            </div>
            <div class="animate" style="width: 100%; display: flex; justify-content: center; margin-top: 20px">
                <component v-if="section.component" :is="section.component" style="height: 300px" ></component>
                <img v-else-if="section.img" :src="section.img" alt="" style="max-width: 80vw; max-height: 200px; min-height: 200px; height: 200px">
            </div>
        </div>
    </div>
</template>

<script>
import baselining from "@/assets/images/versions/500/Baseline_V1.png";
import norms from "@/assets/images/versions/500/normative-data.png";
import report from "@/assets/images/versions/500/clinical-report.png";

import SitToStandAnimation from "@/components/Release520/SitToStandAnimation";

export default {
    name: "Release520Sections",
    components: {SitToStandAnimation},
    data() {
        return {
            sections: [
                {
                    title: '<div>Tests → Screenings<br />Sessions → Assessments</div>',
                    description: `<div><p>To eliminate confusion, we have renamed Tests and Sessions to be more representative of what they are each used for - Screenings and Assessments.</p><p><span style="color: #333; font-weight: 500">Screenings (formally Tests)</span> - Shorter in duration, requiring only 1 repetition of each test in the selected protocol.</p><p><span style="color: #333; font-weight: 500">Assessments (formally Sessions)</span> - Longer in duration, requiring up to three repetitions of each test in the protocol to provide more accurate measures.</p></div>`,
                    // component: SitToStandAnimation
                },
                {
                    title: 'CDC 30-Second Chair Stand',
                    description: `Sway's first functional test has officially been released. For more information on the new test or to get it added to your organization, please reach out to your sales rep or through <a href="https://www.swaymedical.com/" target="_blank"> our website</a>.`,
                    component: SitToStandAnimation
                },
                
                {
                    title: 'Security Updates',
                    description: `<div>We’ve beefed up our security to better protect your data and organization by adding optional password expiration parameters as well as the ability to change your password from within the mobile app. To turn on password expiration functionality for your organization, visit the web portal, click “Organization” on the left sidebar and you will see the option under the “Settings” tab.`,
                },
                {
                    title: 'Minor Updates',
                    description: `<div><ul>
                        <li>Added ability to add notes to a screening or assessment when reviewing results immediately after.</li>
                        <li>Updated instruction screen graphics.</li>
                        <li>Updated test countdown screen to prompt user to place device against the chest when necessary.</li>
                    </ul></div>`,
                },
                // {
                //     title: 'New & Improved Dashboard',
                //     description: `Access what you need when you need it. Sway's new dashboard allows you to search profiles, see all open events, and all active codes in one spot. Search across all profiles and quickly access the most recent activity including athletes in current post-injury care.`,
                //     img: dashboard
                // },
                // {
                //     title: 'Easy Test Code Management',
                //     description: `Manage all your testing codes in one place. Codes can be created for a group or an individual, for either a multi-test baseline session or just a single event test. Test and session codes can be used in person or remote. In real-time, you will see each profile's level of consistency across all tests and protocols you assign.`,
                //     img: codeManagement
                // },
                // {
                //     title: 'Seamless Profile Management',
                //     description: `Sway's new profile screen displays all your athletes' information and objective data in one place. Profile creation has been simplified with additional data being collected. View the established baselines for the profile, see their trends over time, and view a history of their results. Easily and quickly administer a new test or session directly from the profile screen.`,
                //     img: profileManagement
                // },
                // {
                //     title: 'Profile Trends',
                //     description: `Quickly understand a profile's performance over time with individual charts for each test and protocol. See each profile's variability in their results and make educated decisions on an athlete's return to play when a sudden drop in the performance trend happens.`,
                //     img: trends
                // },
                // {
                //     title: 'New Error Detection',
                //     description: `Sway's testing is now even more reliable and consistent with error detection throughout the app. Error detection can now be identified immediately if a user pulls the phone away from the chest to take a peek at the device.  If an error is detected, the athlete will be notified in real-time and required to retake the test to get a more accurate baseline.`,
                //     img: errorDetection
                // },
            ]
        }
    }
}
</script>

<style scoped>
.section-title {
    font-weight: 500;
    margin: 20px 0;
    font-size: 16px;
    color: #333
}

.section-description {
    font-size: 14px;
    color: #757575
}
</style>