<template>
    <div >
        <div>
            The Sway Balance™ System is a medical device and should only be administered with a full understanding of the testing procedure after reading the user manual. It is contraindicated for the Sway Balance™ System to be used in any setting without the proper understanding of the testing procedures. Testing should be performed in a controlled environment to ensure test repeatability. Improper testing conditions include:
        </div>
        <div>
            <ul>
                <li v-for="item in list" :key="item">
                    {{item}}
                </li>
            </ul>
            
        </div>
        <div>
            Testing in an improper environment may lead to inaccurate results.
        </div>
        

    </div>
</template>

<script>
export default {
    name: "AboutContraindications",
    data() {
        return {
            list: [
                `Unstable or grass surface (hard and flat surfaces only)`,
                `Wet or slippery surfaces`,
                `High noise level environments`,
                `Cluttered or hazardous environments`,
                `Distracted or socially interactive environments`,
            ]
        }
    }
}
</script>

<style scoped>

</style>