<template>
    <div>
        <div
            v-for="(section, sectionIndex) in sections"
            :key="sectionIndex"
            style="margin-bottom: 30px; margin-top: 20px"
        >


            <div class="section-title animate" v-html="section.title">
                <!-- {{section.title}} -->
            </div>
            <div class="section-description animate" v-html="section.description">
            </div>
            <div class="animate" style="width: 100%; display: flex; justify-content: center; margin-top: 20px">
                <component v-if="section.component" :is="section.component" style="height: 300px" ></component>
                <img v-else-if="section.img" :src="section.img" alt="" style="max-width: 80vw; max-height: 400px; min-height: 200px; height: 400px">
                <iframe v-if="section.youtubeUrl" width="100%" style="min-height: 200px" :src="section.youtubeUrl"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import baselining from "@/assets/images/versions/500/Baseline_V1.png";
import VomsPic from "@/assets/images/versions/540/VOMS-screen.png";
import baselineEditPic from "@/assets/images/versions/540/code-profile-details.png";
import norms from "@/assets/images/versions/500/normative-data.png";
import codeShowResults from "@/assets/images/versions/530/code-show-results.png";
import cuedStroopDarkTheme from "@/assets/images/versions/530/cued-stroop-dark-theme.png";

import SitToStandAnimation from "@/components/Release520/SitToStandAnimation";

export default {
    name: "Release530Sections",
    components: {SitToStandAnimation},
    data() {
        return {
            sections: [
                {
                    title: '<div>New Test - VOMS</div>',
                    description: `<div><p>We have added the VOMS screening to the platform! If you 
									you would like to get access, please reach out to your sales rep or <a href="mailto:sales@swaymedical.com">sales@swaymedical.com</a>.</p></div>`,
                    // youtubeUrl: 'https://www.youtube.com/embed/rOECayR31fw',
	                img: VomsPic
                    // component: SitToStandAnimation
                },
                {
                    title: '<div>View/Edit Test Scores within a Code</div>',
                    description: `<div><p>We have added the ability to view and edit the results of a test
										taken through a Code on the Code's page. This functionality only applies
										to codes that are <i>Baseline Assessments</i>.
                        
                    </p></div>`,
	                img: baselineEditPic
                    // component: SitToStandAnimation
                },
                {
                    title: 'Minor Updates',
                    description: `<div>
                    <ul>
                        <li>Impulse Controls results now display the number of errors that occured during the test.</li>
                        <li>Composite scores have been added to balance protocols, such as mBESS.</li>
                        <li>Inspection Time score change from baseline is now displayed in milliseconds. Previously it displayed the percent change.</li>
                        <li>Various bug fixes.</li>
                    </ul>
                    </div>`,
                },
            ]
        }
    }
}
</script>

<style scoped>
.section-title {
    font-weight: 900;
    margin: 20px 0;
    font-size: 16px;
    color: #333
}

.section-description {
    font-size: 14px;
    color: #757575
}
</style>