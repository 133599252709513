<template>
    <div style="display: flex; justify-content: center; background-color: white;  ">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <div style="max-height: 50px; margin: 20px" class="animate">
                <img :src="swayLogo" style="max-width: 100%; max-height: 50px">
            </div>
            <div class="header animate">
                <div v-if="message && message !== ''" style="text-align: center">
                    <h2 style="color: red">{{message}}</h2>
                </div>
                <div>
                    What's new in 5.5?
                </div>

                <div style="height: 2px; width: 100%; background-color: #34d3fd">

                </div>
            </div>

            <Release550Sections />
        </div>

    </div>
</template>

<script>
import swayLogo from '@/assets/images/sway-logo.png'
import Release550Sections from "@/views/Versions/Release550Sections";

export default {
    name: "Release550",
    components: {Release550Sections},
    data() {
        return {
            swayLogo,
            
        }
    },
    mounted() {
        // gsap.from('.animate', {
        //     y: 50,
        //     opacity: 0,
        //     stagger: 0.05
        // })
    },
    computed: {
        message() {
            var message = this.$route.query.message;
            if (message == 'pleaseupdate') {
                return 'Please update Sway to the latest version.';
            }
        },
    }
}
</script>

<style>




.header {
    font-size: 20px;
    font-weight: 800;
    /*text-decoration: underline #34d3fd;*/
}
</style>