<template>
    <div style="width: 100vw">
        <router-view style="padding: 20px; "></router-view>
    </div>
    
</template>

<script>
export default {
    name: "Mobile"
}
</script>

<style scoped>

</style>