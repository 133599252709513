<template>
    <div style="max-height: 50vh; height: 300px">
        <svg ref="graphic-1" width="100%" height="100%" viewBox="0 0 241 447" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M112.814 438H106.725C105.866 438 105.162 437.309 105.15 436.449L103.311 323.549H116.234L114.395 436.449C114.377 437.309 113.674 438 112.814 438ZM214.173 341.594H116.228V323.543H214.173V341.594ZM214.173 313.908H104.826C102.626 313.908 100.847 315.687 100.847 317.887V319.57C100.847 321.77 102.626 323.549 104.826 323.549H214.173V313.908ZM228.07 437.062C228.996 437.062 229.723 436.275 229.639 435.349C226.303 397.518 224.848 356.206 224.968 311.859C225.083 270.318 227.09 231.477 230.264 195.713C230.348 194.787 229.621 194 228.695 194H221.073C220.256 194 219.571 194.619 219.499 195.437C216.313 231.279 214.293 270.21 214.179 311.859C214.059 356.314 217.341 397.728 220.737 435.632C220.809 436.443 221.488 437.068 222.306 437.068H228.07V437.062Z" stroke="#8C8C8C" stroke-width="1.35488" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M84.6321 398.657C84.6321 398.657 87.1896 402.536 87.488 406.226C87.7864 409.922 89.1077 414.873 89.1077 414.873L90.3682 419.958L91.3486 424.336C91.3486 424.336 92.1037 427.533 88.6084 428.215C85.1132 428.891 74.2132 429.28 74.2132 429.28L63.5326 428.373L55.6712 427.819C55.6712 427.819 41.5135 430.504 41.3004 430.535C41.0934 430.559 36.161 431.534 31.6732 430.456C27.1853 429.378 19.4945 426.826 19.4945 426.826L10.9329 422.126C10.9329 422.126 8.418 420.713 9.24006 416.201C10.0621 411.682 11.1643 412.358 13.7583 411.116C16.3524 409.874 20.4992 410.982 22.3504 410.678C24.2016 410.373 34.4316 406.519 34.4316 406.519L53.6983 396.404L56.5055 396.027C56.5055 396.027 61.7788 398.84 62.6496 399.753C63.5204 400.667 69.8046 404.637 71.0712 404.96C71.9906 405.197 78.7315 409.04 80.6497 403.565L82.446 399.278C82.4521 399.272 83.7431 397.732 84.6321 398.657Z" fill="#CCCCCC"/>
            <path d="M74.3655 406.312C74.3655 406.312 78.9203 407.395 80.7167 403.888C82.513 400.381 82.0137 397.354 84.626 398.663C87.2383 399.966 88.9677 413.284 89.2722 416.438C89.5766 419.592 90.6423 419.075 90.3317 420.847C90.0212 422.619 93.0841 425.408 89.7471 427.722C86.4102 430.042 65.7917 428.726 58.0583 427.892C51.6523 427.204 47.1705 430.188 38.8525 430.919C35.9053 431.18 26.4242 430.437 13.3808 423.928C13.3808 423.928 9.89772 422.052 9.17918 419.574C8.46064 417.096 10.1352 413.339 10.1352 413.339C10.1352 413.339 12.0473 410.556 16.3524 410.982C20.6637 411.408 27.6542 409.551 30.1752 408.388C32.6962 407.225 52.0664 397.354 54.3925 396.027C56.0062 395.107 58.996 397.275 60.7741 398.554C62.5522 399.832 66.4981 401.866 67.6307 403.401C68.7755 404.923 73.7322 406.208 74.3655 406.312Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M90.3319 420.841C90.3319 420.841 91.1235 424.689 67.2289 422.613C43.3344 420.536 49.6795 426.808 29.402 427.101C29.402 427.101 24.4392 427.107 17.7348 423.66C11.0244 420.213 9.01489 417.607 9.01489 417.607" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M62.6496 399.747C62.6496 399.747 54.1123 397.33 52.8579 404.071C51.8593 409.429 49.3748 402.28 46.6468 408.297C46.044 409.624 42.7557 406.056 43.0297 411.061C43.1211 412.803 39.2056 408.589 39.2605 414.045C39.2787 415.89 34.7422 410.83 34.1089 415.744C34.1089 415.744 34.5351 418.381 27.8795 418.125C21.2238 417.869 14.5438 413.576 13.8436 413.059C13.1433 412.541 9.31311 416.542 10.263 418.837" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M87.5063 405.41C87.5124 405.429 75.6625 409.959 74.3777 423.1" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.4135 409.764C25.4135 409.764 31.0279 414.538 30.8148 415.354C30.6016 416.176 18.4778 413.789 17.3147 411.475" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M49.6609 398.493C49.6609 398.493 49.5696 403.504 49.7949 405.538C49.7949 405.538 49.4904 406.245 47.9986 405.489C47.9986 405.489 47.8524 398.742 48.1995 398.213C48.1995 398.213 49.667 397.774 49.6609 398.493Z" fill="white"/>
            <path d="M49.6609 398.493C49.6609 398.493 49.5696 403.504 49.7949 405.538C49.7949 405.538 49.4904 406.245 47.9986 405.489C47.9986 405.489 47.8524 398.742 48.1995 398.213C48.1995 398.213 49.667 397.774 49.6609 398.493Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.0587 401.251C44.0587 401.251 45.2157 406.165 45.9403 408.096C45.9403 408.096 45.8246 408.863 44.1927 408.492C44.1927 408.492 42.3781 401.939 42.579 401.343C42.579 401.343 43.8882 400.545 44.0587 401.251Z" fill="white"/>
            <path d="M44.0587 401.251C44.0587 401.251 45.2157 406.165 45.9403 408.096C45.9403 408.096 45.8246 408.863 44.1927 408.492C44.1927 408.492 42.3781 401.939 42.579 401.343C42.579 401.343 43.8882 400.545 44.0587 401.251Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M40.0399 404.211C40.0399 404.211 41.1969 409.125 41.9215 411.055C41.9215 411.055 41.8058 411.822 40.1739 411.451C40.1739 411.451 38.3593 404.899 38.5602 404.302C38.5602 404.302 39.8694 403.51 40.0399 404.211Z" fill="white"/>
            <path d="M40.0399 404.211C40.0399 404.211 41.1969 409.125 41.9215 411.055C41.9215 411.055 41.8058 411.822 40.1739 411.451C40.1739 411.451 38.3593 404.899 38.5602 404.302C38.5602 404.302 39.8694 403.51 40.0399 404.211Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M35.278 405.964C35.278 405.964 36.5568 411.414 37.3301 413.558C37.3301 413.558 37.2327 414.398 35.5825 413.954C35.5825 413.954 33.6035 406.683 33.7922 406.025C33.7922 406.025 35.0893 405.179 35.278 405.964Z" fill="white"/>
            <path d="M35.278 405.964C35.278 405.964 36.5568 411.414 37.3301 413.558C37.3301 413.558 37.2327 414.398 35.5825 413.954C35.5825 413.954 33.6035 406.683 33.7922 406.025C33.7922 406.025 35.0893 405.179 35.278 405.964Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M57.1996 401.385C57.1996 401.385 50.2882 398.7 47.274 399.132C44.2597 399.565 46.184 399.796 46.184 399.796C46.184 399.796 48.1082 400.186 50.4465 401.044C53.1136 402.025 54.9648 402.822 54.9648 402.822L55.9817 402.749L57.1996 401.385Z" fill="white"/>
            <path d="M57.1997 401.385C57.1997 401.385 56.7369 403.127 54.971 402.822C53.2051 402.518 48.5468 399.65 45.1428 399.747C45.1428 399.747 46.7565 398.974 47.8891 398.937C47.8891 398.937 55.8296 400.387 57.1997 401.385Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M50.5806 405.148C50.5806 405.148 43.0299 402.701 39.8147 403.236C36.5995 403.772 38.6821 403.937 38.6821 403.937C38.6821 403.937 40.7646 404.259 43.3222 405.045C46.239 405.934 48.2667 406.665 48.2667 406.665L49.3567 406.555L50.5806 405.148Z" fill="white"/>
            <path d="M50.5684 405.404C50.5684 405.404 50.0874 407.146 48.1875 406.823C46.2876 406.5 41.2517 403.584 37.5981 403.644C37.5981 403.644 39.3214 402.883 40.5393 402.859C40.5393 402.865 49.0826 404.393 50.5684 405.404Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45.8064 409.435C45.8064 409.435 39.2116 405.173 36.0147 405.045C34.1818 404.972 34.7664 405.459 34.7664 405.459C34.7664 405.459 36.6724 406.263 38.9193 407.621C41.4768 409.174 43.2306 410.361 43.2306 410.361L44.2901 410.513L45.8064 409.435Z" fill="white"/>
            <path d="M45.8064 409.435C45.8064 409.435 44.9904 411.043 43.2245 410.355C41.4647 409.667 38.0181 406.086 34.4863 405.429C34.4863 405.429 35.5032 404.765 36.6785 404.984C36.6785 404.984 44.5885 408.157 45.8064 409.435Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M41.9642 411.908C41.9642 411.908 34.5961 407.249 31.046 407.061C29.0182 406.951 29.6698 407.481 29.6698 407.481C29.6698 407.481 31.795 408.37 34.2977 409.856C37.1597 411.554 39.1204 412.851 39.1204 412.851L40.3018 413.028L41.9642 411.908Z" fill="white"/>
            <path d="M41.9641 411.908C41.9641 411.908 41.0872 413.613 39.1203 412.851C37.1596 412.09 33.2868 408.205 29.3652 407.456C29.3652 407.456 30.4796 406.762 31.7827 407.012C31.7827 407.012 40.5879 410.519 41.9641 411.908Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M36.423 414.672C36.423 414.672 30.8147 409.35 27.8127 408.126C27.1429 407.858 27.1733 408.613 27.1733 408.613C27.1733 408.613 28.1598 409.515 30.0414 411.378C32.1909 413.503 33.6341 415.086 33.6341 415.086L34.651 415.464L36.423 414.672Z" fill="white"/>
            <path d="M36.4229 414.672C36.4229 414.672 35.2112 416.152 33.634 415.086C32.0569 414.015 29.2802 410.647 26.8201 408.51C26.8201 408.51 27.3803 407.743 28.4885 408.211C28.4946 408.205 35.5339 413.101 36.4229 414.672Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M113.508 276.542C113.508 276.542 88.6693 277.851 82.7383 280.457C82.7383 280.457 66.2605 281.967 61.9249 287.63C61.9249 287.63 54.8247 289.226 54.4411 304.638C54.4411 304.638 54.0331 322.169 56.9012 328.606C59.7693 335.042 57.1874 349.334 65.475 380.462C65.475 380.462 66.6502 397.007 64.1841 402.822C64.1841 402.822 59.0386 409.715 57.7659 410.379C56.4932 411.043 45.5872 412.504 39.2239 415.184C32.8605 417.863 33.305 420.78 29.8524 421.608C26.3997 422.436 16.5716 422.351 15.4085 426.029C14.2515 429.707 19.0804 423.1 20.1277 424.494C20.1277 424.494 19.6649 427.326 24.9992 427.941C24.9992 427.941 27.2766 430.182 30.7414 429.975C30.7414 429.975 32.4038 433.47 36.7881 432.398C36.7881 432.398 37.0439 434.286 42.1711 433.555C42.1711 433.555 48.3396 437.623 60.6462 436.387C72.9527 435.151 71.5034 437.038 76.9777 437.909C82.4521 438.78 87.4027 439.273 89.3513 433.08C91.2999 426.887 87.8228 422.387 87.9324 417.309C88.0421 412.236 90.5996 373.673 93.6808 365.921C96.762 358.169 98.5644 335.328 86.8607 318.875C86.8607 318.875 104.617 317.657 114.981 323.259" fill="white"/>
            <path d="M113.508 276.542C113.508 276.542 88.6693 277.851 82.7383 280.457C82.7383 280.457 66.2605 281.967 61.9249 287.63C61.9249 287.63 54.8247 289.226 54.4411 304.638C54.4411 304.638 54.0331 322.169 56.9012 328.606C59.7693 335.042 57.1874 349.334 65.475 380.462C65.475 380.462 66.6502 397.007 64.1841 402.822C64.1841 402.822 59.0386 409.715 57.7659 410.379C56.4932 411.043 45.5872 412.504 39.2239 415.184C32.8605 417.863 33.305 420.78 29.8524 421.608C26.3997 422.436 16.5716 422.351 15.4085 426.029C14.2515 429.707 19.0804 423.1 20.1277 424.494C20.1277 424.494 19.6649 427.326 24.9992 427.941C24.9992 427.941 27.2766 430.182 30.7414 429.975C30.7414 429.975 32.4038 433.47 36.7881 432.398C36.7881 432.398 37.0439 434.286 42.1711 433.555C42.1711 433.555 48.3396 437.623 60.6462 436.387C72.9527 435.151 71.5034 437.038 76.9777 437.909C82.4521 438.78 87.4027 439.273 89.3513 433.08C91.2999 426.887 87.8228 422.387 87.9325 417.309C88.0421 412.236 90.5996 373.673 93.6808 365.921C96.762 358.169 98.5644 335.328 86.8607 318.875C86.8607 318.875 104.617 317.657 114.981 323.259" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M121.193 259.248L103.783 260.356C103.783 260.356 74.6031 263.103 74.6213 264.387C74.6457 265.672 80.6559 267.767 85.4116 279.544L82.6045 280.415C82.6045 280.415 88.4015 295.455 92.2073 298.847C96.0132 302.239 99.0396 310.953 111.31 320.111C123.574 329.269 127.337 341.143 128.743 347.33L156.925 335.98C156.925 335.98 169.128 333.367 172.696 332.466C179.936 330.639 193.899 324.099 201.858 314.016C209.433 304.419 208.227 300.716 207.886 301.794C207.886 301.794 192.913 301.618 181.495 295.047C178.11 293.099 171.722 290.17 167.563 287.46C159.592 282.278 153.326 276.858 146.64 273.327C133.938 266.61 121.193 259.248 121.193 259.248Z" fill="#CCCCCC"/>
            <path d="M119.597 259.431C119.597 259.431 81.5144 261.233 74.7614 264.43C74.7614 264.43 43.4805 267.14 41.7389 278.082C39.9974 289.025 38.9317 294.438 41.5745 306.331C42.9091 325.767 54.5 345 57.1875 368.789C59.8751 392.578 53.5 395 53.5 395" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M85.8197 279.526C85.8197 279.526 79.8217 267.298 74.7615 264.43" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M207.892 301.794C199.185 329.111 162.484 334.591 162.484 334.591C151.651 336.729 128.883 347.373 128.883 347.373C127.544 345.035 129.048 334.177 110.043 319.003C91.0381 303.828 81.9224 280.549 81.9224 280.549L117.831 272.973" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M136.775 235.792C136.848 257.519 120.608 254.279 120.078 257.896C119.548 261.519 143.09 269.417 155.341 278.259C167.593 287.101 168.902 290.194 186.665 297.16C204.421 304.133 210.712 300.528 214 300.211C214 300.211 207.119 251.673 208.763 231.03C210.407 210.393 214.244 171.245 203.198 158.025" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M92.5056 278.904C92.5056 278.904 98.8507 303.499 128.494 325.597C128.494 325.597 136.732 333.995 135.935 343.926" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2 2"/>
            <path d="M81.3926 262.877C81.3926 262.877 88.5902 269.965 90.009 278.996" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2 2"/>
            <path d="M151.755 175.733C151.755 175.733 153.77 190.439 155.214 195.048C156.523 199.238 153.405 205.741 159.135 214.656C159.135 214.656 157.978 221.178 158.484 222.889" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M158.483 222.889C158.483 222.889 154.294 222.535 149.709 223.449C145.123 224.368 139.339 218.449 136.872 217.634C136.872 217.634 135.039 212.573 135.545 210.99C136.05 209.407 136.367 192.077 136.367 192.077C136.367 192.077 135.922 190.688 134.595 191.443" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M127.72 225.604C127.72 225.604 125.327 223.784 124.633 222.493C123.939 221.208 121.85 218.991 121.74 217.92C121.637 216.842 120.054 216.611 119.567 214.187C119.079 211.763 118.215 212.372 118.257 211.489C118.3 210.606 117.015 211.081 117.32 206.965C117.32 206.965 114.969 204.365 116.632 202.276C116.632 202.276 113.995 200.06 115.852 196.729C117.709 193.398 120.626 190.183 120.626 190.183C120.626 190.183 123.914 187.102 124.907 186.639C124.907 186.639 128.232 183.984 129.748 183.899C131.264 183.813 131.891 183.886 132.074 185.202" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M131.441 188.021C136.142 187.96 134.711 191.754 133.737 192.387C132.768 193.02 131.331 194.074 131.331 194.074C131.331 194.074 134.145 193.489 134.26 195.024C134.473 197.721 133.481 198.251 132.239 199.159C130.996 200.066 127.239 202.678 127.239 202.678C127.239 202.678 130.887 203.372 127.452 206.472C125.394 208.329 126.015 211.727 125.954 212.415C125.394 218.663 128.823 226.761 128.823 226.761C145.605 247.368 161.565 252.331 169.28 256.374C176.995 260.423 180.6 256.246 180.6 256.246C180.6 256.246 183.76 253.969 189.71 245.87C195.653 237.771 194.77 200.456 195.275 198.872C195.781 197.289 198.436 171.927 198.436 171.927" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M139.704 174.198C139.704 174.198 145.124 159.968 151.383 154" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M155.743 195.688C155.768 196.102 171.131 176.518 195.196 199.676" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M131.441 188.021L136.659 173.005L139.613 173.729L134.601 190.573" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M60.3827 404.377L50.0028 425.833C50.0028 425.833 40.467 428.181 40.2649 428.223C40.0568 428.264 35.2533 429.539 30.7292 428.851C26.205 428.169 18.4052 426.331 18.4052 426.331L9.57102 422.53C9.57102 422.53 6.97307 421.385 7.43678 417.068C7.90643 412.757 9.04787 413.308 11.515 411.95C13.9822 410.586 18.1734 411.328 19.9866 410.907C21.7939 410.485 31.615 406.103 31.615 406.103L49.8958 395.156L52.6483 394.598C52.6483 394.598 58.082 396.864 59.0153 397.658C59.9487 398.453 60.9177 398.358 61.1258 399.633" fill="#CCCCCC"/>
            <path d="M70.8697 424.167C57.2082 423.123 52.9635 424.819 50.2942 425.471C45.5679 426.634 40.7763 428.027 37.9524 428.81C35.1345 429.593 25.6226 429.272 12.2167 424.095C12.2167 424.095 8.6259 422.583 7.72821 420.3C6.82458 418.017 8.19192 414.352 8.19192 414.352C8.19192 414.352 9.8684 411.588 14.1607 411.677C18.4589 411.766 25.2302 409.501 27.632 408.22C30.0337 406.945 48.4394 396.217 50.639 394.8C52.1609 393.816 55.288 395.636 57.1487 396.71C59.0095 397.783 59.9013 397.569 61.1438 398.933" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M64.2173 419.084C51.1325 416.938 48.3443 424.149 28.3038 425.916C28.3038 425.916 23.3992 426.284 16.4971 423.532C9.59498 420.78 7.40723 418.474 7.40723 418.474" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M59.0869 397.706C59.0869 397.706 50.4548 396.057 49.7355 402.497C49.1647 407.615 46.1566 401.068 43.9153 406.933C43.4219 408.232 39.8965 405.107 40.5445 409.804C40.7704 411.44 36.5674 407.758 37.0489 412.893C37.2094 414.625 32.3286 410.201 32.0848 414.874C32.0848 414.874 33.476 415.627 29.5523 415.811C29.5523 415.811 25.0698 408.997 21.8 410.195" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M60.2104 418.96C59.4732 418.989 51.5248 409.75 53.0051 405.753C54.4854 401.756 56.4413 400.256 59.2533 400.825C62.0652 401.388 60.4958 405.202 60.6801 406.939" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M32.0549 425.501C32.0549 425.501 26.2824 413.249 21.9128 412.318C21.9128 412.318 14.3211 411.606 13.4234 413.32C13.4234 413.32 19.6537 415.188 11.9609 421.474" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M46.1388 397.504C46.1388 397.504 46.436 402.231 46.8165 404.128C46.8165 404.128 46.5727 404.816 45.0389 404.211C45.0389 404.211 44.3731 397.866 44.6763 397.344C44.6763 397.344 46.0912 396.822 46.1388 397.504Z" fill="white"/>
            <path d="M46.1388 397.504C46.1388 397.504 46.436 402.231 46.8165 404.128C46.8165 404.128 46.5727 404.816 45.0389 404.211C45.0389 404.211 44.3731 397.866 44.6763 397.344C44.6763 397.344 46.0912 396.822 46.1388 397.504Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M40.8119 400.517C40.8119 400.517 42.3338 405.059 43.2017 406.827C43.2017 406.827 43.1423 407.556 41.5015 407.325C41.5015 407.325 39.2008 401.288 39.3554 400.706C39.3554 400.706 40.586 399.864 40.8119 400.517Z" fill="white"/>
            <path d="M40.8119 400.517C40.8119 400.517 42.3338 405.059 43.2017 406.827C43.2017 406.827 43.1423 407.556 41.5015 407.325C41.5015 407.325 39.2008 401.288 39.3554 400.706C39.3554 400.706 40.586 399.864 40.8119 400.517Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M37.0607 403.6C37.0607 403.6 38.5826 408.143 39.4505 409.91C39.4505 409.91 39.3911 410.64 37.7503 410.414C37.7503 410.414 35.4496 404.377 35.6041 403.796C35.6041 403.79 36.8407 402.948 37.0607 403.6Z" fill="white"/>
            <path d="M37.0607 403.6C37.0607 403.6 38.5826 408.143 39.4505 409.91C39.4505 409.91 39.3911 410.64 37.7503 410.414C37.7503 410.414 35.4496 404.377 35.6041 403.796C35.6041 403.79 36.8407 402.948 37.0607 403.6Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M32.4889 405.599C32.4889 405.599 34.1713 410.64 35.1047 412.603C35.1047 412.603 35.069 413.403 33.4044 413.107C33.4044 413.107 30.8838 406.406 31.0205 405.771C31.0205 405.765 32.2452 404.875 32.4889 405.599Z" fill="white"/>
            <path d="M32.4889 405.599C32.4889 405.599 34.1713 410.64 35.1047 412.603C35.1047 412.603 35.069 413.403 33.4044 413.107C33.4044 413.107 30.8838 406.406 31.0205 405.771C31.0205 405.765 32.2452 404.875 32.4889 405.599Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M53.8254 399.669C53.8254 399.669 46.7866 397.647 43.8319 398.275C40.8832 398.91 42.8094 398.981 42.8094 398.981C42.8094 398.981 44.7415 399.206 47.1255 399.847C49.8423 400.576 51.7328 401.187 51.7328 401.187L52.7316 401.045L53.8254 399.669Z" fill="white"/>
            <path d="M53.8254 399.669C53.8254 399.669 53.5044 401.341 51.7328 401.187C49.9612 401.033 45.128 398.672 41.769 399.01C41.769 399.01 43.3028 398.162 44.4205 398.05C44.4205 398.056 52.3868 398.833 53.8254 399.669Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M47.5654 403.701C47.5654 403.701 39.9024 401.946 36.7634 402.687C33.6245 403.429 35.6933 403.429 35.6933 403.429C35.6933 403.429 37.78 403.577 40.3661 404.128C43.3207 404.751 45.3836 405.291 45.3836 405.291L46.4478 405.113L47.5654 403.701Z" fill="white"/>
            <path d="M47.5714 403.95C47.5714 403.95 47.2326 405.623 45.3242 405.463C43.4219 405.302 38.2141 402.919 34.6055 403.251C34.6055 403.251 36.2522 402.409 37.4531 402.296C37.4531 402.29 46.0258 403.108 47.5714 403.95Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M43.172 408.096C43.172 408.096 36.3234 404.561 33.1429 404.68C31.3296 404.745 31.936 405.16 31.936 405.16C31.936 405.16 33.886 405.777 36.2105 406.892C38.8619 408.167 40.687 409.157 40.687 409.157L41.7512 409.222L43.172 408.096Z" fill="white"/>
            <path d="M43.1722 408.096C43.1722 408.096 42.4885 409.667 40.6932 409.157C38.8978 408.641 35.2119 405.516 31.6687 405.16C31.6687 405.16 32.6199 404.46 33.8029 404.579C33.797 404.573 41.8643 406.975 43.1722 408.096Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M39.5634 410.705C39.5634 410.705 31.9123 406.862 28.3928 406.945C26.3775 406.993 27.0612 407.443 27.0612 407.443C27.0612 407.443 29.2311 408.125 31.829 409.341C34.7896 410.735 36.8288 411.808 36.8288 411.808L38.0059 411.891L39.5634 410.705Z" fill="white"/>
            <path d="M39.5635 410.705C39.5635 410.705 38.8263 412.377 36.8229 411.802C34.8194 411.233 30.6936 407.858 26.7581 407.437C26.7581 407.437 27.8044 406.702 29.1182 406.838C29.1123 406.838 38.0951 409.501 39.5635 410.705Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M34.2963 413.717C34.2963 413.717 28.3394 409.11 25.2778 408.185C24.5941 407.983 24.6833 408.689 24.6833 408.689C24.6833 408.689 25.7296 409.465 27.733 411.079C30.0218 412.923 31.5735 414.305 31.5735 414.305L32.6079 414.583L34.2963 413.717Z" fill="white"/>
            <path d="M34.2962 413.718C34.2962 413.718 33.2142 415.2 31.5675 414.305C29.9267 413.415 26.9126 410.444 24.3206 408.612C24.3206 408.612 24.814 407.847 25.9554 408.208C25.9495 408.208 33.2915 412.3 34.2962 413.718Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M90.9936 406.717C90.9936 406.717 93.7878 410.703 94.2039 414.557C94.6201 418.412 96.1539 423.553 96.1539 423.553L97.6223 428.831L98.7815 433.386C98.7815 433.386 99.6673 436.713 96.0171 437.519C92.3669 438.332 80.9526 439.049 80.9526 439.049L69.7285 438.409L61.2628 438.041C61.2628 438.041 46.6977 441.279 46.4837 441.309C46.2637 441.338 41.1213 442.501 36.3832 441.498C31.645 440.502 23.5063 438.053 23.5063 438.053L14.3927 433.38C14.3927 433.38 11.7115 431.974 12.4428 427.23C13.174 422.486 14.3511 423.156 17.0323 421.78C19.7135 420.404 24.1008 421.442 26.033 421.075C27.9651 420.707 38.5828 416.378 38.5828 416.378L58.4925 405.241L61.4293 404.766C61.4293 404.766 67.0414 407.56 67.9807 408.485C68.92 409.416 75.6259 413.389 76.9635 413.691C77.9325 413.911 85.1141 417.742 86.9689 411.96L88.7286 407.423C88.7286 407.429 90.0424 405.775 90.9936 406.717Z" fill="#CCCCCC"/>
            <path d="M80.1438 414.826C80.1438 414.826 84.9473 415.829 86.7368 412.11C88.5202 408.392 87.9079 405.243 90.6842 406.53C93.4605 407.817 95.6601 421.694 96.0704 424.985C96.4806 428.276 97.5804 427.707 97.3069 429.563C97.0334 431.425 100.327 434.254 96.8967 436.774C93.4665 439.295 71.803 438.512 63.6762 437.865C56.9406 437.332 52.3273 440.576 43.6238 441.584C40.5384 441.94 30.5806 441.441 16.711 435.001C16.711 435.001 13.0013 433.139 12.1809 430.565C11.3605 427.992 13.0013 424.012 13.0013 424.012C13.0013 424.012 14.9215 421.047 19.4575 421.367C23.9876 421.694 31.2642 419.547 33.8741 418.26C36.4839 416.973 56.5125 406.085 58.9083 404.632C60.5729 403.624 63.7713 405.801 65.6737 407.093C67.5761 408.38 71.7673 410.391 73.0039 411.968C74.2404 413.54 79.4779 414.737 80.1438 414.826Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M97.3071 429.563C97.3071 429.563 98.2523 433.566 73.1348 432.083C48.0173 430.601 54.8541 436.976 33.6008 437.865C33.6008 437.865 28.3989 438.014 21.265 434.604C14.131 431.194 11.9492 428.525 11.9492 428.525" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M67.6714 408.303C67.6714 408.303 58.6529 406.02 57.5293 413.107C56.6376 418.74 53.8256 411.334 51.1385 417.703C50.544 419.114 46.9948 415.473 47.4229 420.697C47.5715 422.518 43.3387 418.224 43.5586 423.929C43.63 425.857 38.7254 420.697 38.2082 425.857C38.2082 425.857 38.7313 428.596 31.74 428.525C24.7547 428.454 17.6267 424.149 16.8776 423.633C16.1285 423.117 12.2286 427.404 13.2928 429.783" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M93.8945 413.504C93.9004 413.522 81.6062 418.604 80.6372 432.386" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M28.9161 419.843C28.9161 419.843 34.9383 424.671 34.7362 425.536C34.54 426.402 21.7583 424.256 20.4683 421.866" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M54.0099 407.36C54.0099 407.36 54.0574 412.603 54.3547 414.726C54.3547 414.726 54.0575 415.473 52.4701 414.726C52.4701 414.726 52.1253 407.675 52.4701 407.111C52.4761 407.111 54.0039 406.607 54.0099 407.36Z" fill="white"/>
            <path d="M54.0099 407.36C54.0099 407.36 54.0574 412.603 54.3547 414.726C54.3547 414.726 54.0575 415.473 52.4701 414.726C52.4701 414.726 52.1253 407.675 52.4701 407.111C52.4761 407.111 54.0039 406.607 54.0099 407.36Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M48.2194 410.408C48.2194 410.408 49.5749 415.514 50.3893 417.513C50.3893 417.513 50.2882 418.319 48.5642 417.975C48.5642 417.975 46.4716 411.173 46.6678 410.545C46.6678 410.545 48.0232 409.673 48.2194 410.408Z" fill="white"/>
            <path d="M48.2194 410.408C48.2194 410.408 49.5749 415.514 50.3893 417.513C50.3893 417.513 50.2882 418.319 48.5642 417.975C48.5642 417.975 46.4716 411.173 46.6678 410.545C46.6678 410.545 48.0232 409.673 48.2194 410.408Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.0876 413.623C44.0876 413.623 45.443 418.729 46.2575 420.727C46.2575 420.727 46.1564 421.534 44.4324 421.19C44.4324 421.19 42.3397 414.388 42.5359 413.759C42.5419 413.759 43.8914 412.887 44.0876 413.623Z" fill="white"/>
            <path d="M44.0876 413.623C44.0876 413.623 45.443 418.729 46.2575 420.727C46.2575 420.727 46.1564 421.534 44.4324 421.19C44.4324 421.19 42.3397 414.388 42.5359 413.759C42.5419 413.759 43.8914 412.887 44.0876 413.623Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M39.1473 415.585C39.1473 415.585 40.6455 421.249 41.5194 423.473C41.5194 423.473 41.4421 424.356 39.6943 423.935C39.6943 423.935 37.4114 416.386 37.5898 415.698C37.5898 415.698 38.9333 414.773 39.1473 415.585Z" fill="white"/>
            <path d="M39.1473 415.585C39.1473 415.585 40.6455 421.249 41.5194 423.473C41.5194 423.473 41.4421 424.356 39.6943 423.935C39.6943 423.935 37.4114 416.386 37.5898 415.698C37.5898 415.698 38.9333 414.773 39.1473 415.585Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M62.0058 410.165C62.0058 410.165 54.6816 407.556 51.5367 408.096C48.3859 408.635 50.4131 408.819 50.4131 408.819C50.4131 408.819 52.4404 409.169 54.9194 410.005C57.7433 410.954 59.7111 411.731 59.7111 411.731L60.7752 411.624L62.0058 410.165Z" fill="white"/>
            <path d="M62.0057 410.165C62.0057 410.165 61.5718 411.998 59.711 411.737C57.8502 411.47 52.8802 408.606 49.3132 408.801C49.3132 408.801 50.9838 407.941 52.1728 407.876C52.1668 407.876 60.5373 409.163 62.0057 410.165Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M55.1751 414.293C55.1751 414.293 47.185 411.95 43.8261 412.603C40.4732 413.261 42.655 413.368 42.655 413.368C42.655 413.368 44.8487 413.646 47.5536 414.388C50.6331 415.236 52.7852 415.941 52.7852 415.941L53.9207 415.799L55.1751 414.293Z" fill="white"/>
            <path d="M55.1631 414.565C55.1631 414.565 54.7113 416.398 52.7078 416.119C50.7043 415.84 45.342 412.929 41.5134 413.101C41.5134 413.101 43.2969 412.253 44.5751 412.199C44.5751 412.193 53.5758 413.551 55.1631 414.565Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M50.2882 418.912C50.2882 418.912 43.2494 414.643 39.8905 414.601C37.9702 414.577 38.5885 415.07 38.5885 415.07C38.5885 415.07 40.6098 415.852 43.0056 417.216C45.7344 418.764 47.607 419.956 47.607 419.956L48.7247 420.087L50.2882 418.912Z" fill="white"/>
            <path d="M50.2882 418.912C50.2882 418.912 49.4797 420.614 47.613 419.95C45.7462 419.286 42.0306 415.633 38.3091 415.052C38.3091 415.052 39.3554 414.328 40.592 414.524C40.592 414.524 48.9744 417.614 50.2882 418.912Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M46.3288 421.611C46.3288 421.611 38.4696 416.949 34.7421 416.855C32.6138 416.801 33.3094 417.335 33.3094 417.335C33.3094 417.335 35.5625 418.201 38.2318 419.689C41.2816 421.385 43.3742 422.678 43.3742 422.678L44.6167 422.832L46.3288 421.611Z" fill="white"/>
            <path d="M46.3289 421.611C46.3289 421.611 45.461 423.419 43.3802 422.678C41.2995 421.937 37.1261 417.987 32.9944 417.317C32.9944 417.317 34.1418 416.558 35.521 416.783C35.5151 416.783 44.8487 420.205 46.3289 421.611Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M40.6038 424.659C40.6038 424.659 34.5696 419.25 31.3891 418.064C30.6816 417.797 30.7292 418.592 30.7292 418.592C30.7292 418.592 31.7874 419.505 33.8206 421.397C36.1332 423.556 37.6907 425.175 37.6907 425.175L38.7668 425.536L40.6038 424.659Z" fill="white"/>
            <path d="M40.6039 424.659C40.6039 424.659 39.3733 426.242 37.6908 425.169C36.0084 424.095 32.9943 420.65 30.3547 418.491C30.3547 418.491 30.9195 417.673 32.0966 418.13C32.0966 418.13 39.6229 423.046 40.6039 424.659Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg ref="graphic-2" display="none" width="100%" height="100%" viewBox="0 0 241 447" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M112.814 438H106.725C105.866 438 105.162 437.309 105.15 436.449L103.311 323.549H116.234L114.395 436.449C114.377 437.309 113.674 438 112.814 438ZM214.173 341.594H116.228V323.543H214.173V341.594ZM214.173 313.908H104.826C102.626 313.908 100.847 315.687 100.847 317.887V319.57C100.847 321.77 102.626 323.549 104.826 323.549H214.173V313.908ZM228.07 437.062C228.996 437.062 229.723 436.275 229.639 435.349C226.303 397.518 224.848 356.206 224.968 311.859C225.083 270.318 227.09 231.477 230.264 195.713C230.348 194.787 229.621 194 228.695 194H221.073C220.256 194 219.571 194.619 219.499 195.437C216.313 231.279 214.293 270.21 214.179 311.859C214.059 356.314 217.341 397.728 220.737 435.632C220.809 436.443 221.488 437.068 222.306 437.068H228.07V437.062Z" stroke="#8C8C8C" stroke-width="1.35488" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M43.1297 238.547C34.5036 247.81 13.9399 277.408 13.9399 277.408C13.9399 277.408 20.6102 282.129 27.3815 282.662L30.14 279.241C30.14 279.241 34.6403 286.464 37.482 287.424C40.3297 288.385 53.8545 298.615 65.5898 300.394C77.3311 302.173 85.5352 305.666 88.9001 306.976C92.2649 308.287 98.4358 311.068 99.4762 312.106L114.677 288.806L130.872 266.176C130.872 266.176 141.798 252.697 143.38 247.632C144.961 242.574 155.085 230.298 149.949 212.49C149.949 212.49 144.961 216.629 132.328 217.963C119.695 219.298 87.2949 222.642 65.2985 218.746C65.2926 218.74 57.2253 223.407 43.1297 238.547Z" fill="#CCCCCC"/>
            <path d="M49.6097 250.337C49.6097 250.337 26.2757 280.972 23.1546 291.202C23.1546 291.202 16.4665 297.132 17.5069 303.359C17.5069 303.359 19.439 316.85 23.4519 322.336C27.4647 327.821 29.8427 334.493 29.8427 334.493C29.8427 334.493 48.718 373.039 56.0006 384.307C63.2831 395.574 58.6163 405.507 58.4202 408.028C58.224 410.548 37.2739 421.223 35.7877 422.557C35.7877 422.557 27.4647 425.522 25.8298 426.856C25.8298 426.856 18.9931 425.967 19.7362 429.525C20.4794 433.083 22.2629 433.38 22.8574 433.38C23.4519 433.38 24.4922 437.086 28.6537 435.9C28.6537 435.9 31.7748 437.531 34.3014 434.714C34.3014 434.714 36.0849 437.976 40.5436 435.752C40.5436 435.752 42.1785 437.305 45.4482 436.529C48.718 435.752 50.5015 438.865 64.4721 434.566C78.4428 430.266 78.1456 432.49 81.7126 432.045C85.2795 431.601 91.9676 431.601 93.008 426.412C94.0484 421.223 91.2245 414.403 87.9548 409.51C84.685 404.618 79.9291 383.269 79.1859 376.153C78.4428 369.036 75.1731 344.871 69.8226 334.789C64.4721 324.708 51.6905 312.847 51.2446 312.106C51.2446 312.106 59.5675 301.58 65.2153 300.394" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.4198 417.54C33.588 420.979 27.4588 418.993 26.8643 418.845C26.2698 418.696 25.6753 421.513 18.5413 419.882C11.4073 418.252 13.1908 414.842 13.7853 413.211C14.3798 411.58 16.3119 412.025 16.3119 412.025L18.2441 410.839C18.2441 410.839 17.3523 412.025 21.3652 409.801C25.378 407.577 32.6606 406.836 35.9303 404.612C39.2001 402.388 51.6845 396.161 52.4276 392.01C53.1708 387.859 48.2662 374.071 42.9157 366.51C37.5652 358.949 15.7174 324.554 14.5285 321.737C13.3395 318.92 10.367 312.545 8.744 309.876C7.12102 307.208 6.05687 304.687 4.71925 295.94C3.38164 287.193 12.8936 279.039 12.8936 279.039C12.8936 279.039 47.0772 225.708 65.5066 218.74" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M29.5337 279.235C29.5337 279.235 35.9364 294.013 73.2411 302.167C73.2411 302.167 93.454 307.504 99.1017 312.1C99.1017 312.1 125.176 271.478 129.421 267.327C133.666 263.176 157.148 232.498 149.569 212.484" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M55.311 168.707C62.8909 179.826 62.6887 218.26 62.6887 218.26C67.1475 218.556 67.742 221.373 108.465 220.187C149.188 219.001 151.406 210.402 151.406 210.402C151.406 210.402 115.45 145.17 116.788 116.704C118.126 88.2392 97.3955 74.7064 94.5716 71.593" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.5596 278.019C13.5596 278.019 21.401 281.921 26.7039 282.562" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.0134 272.255C17.0134 272.255 22.465 276.946 30.6512 277.728" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2 2"/>
            <path d="M33.5286 274.402C35.6152 276.24 51.8331 292.127 79.0373 295.501C79.0373 295.501 95.2136 299.498 102.431 306.615" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2 2"/>
            <path d="M49.5088 106.439C49.5088 106.439 55.216 119.723 57.7664 123.684C60.0849 127.284 58.8364 134.193 66.5411 141.084C66.5411 141.084 67.1416 147.506 68.063 148.977" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M68.063 148.977C68.063 148.977 64.0263 149.73 59.9481 151.776C55.8698 153.822 48.8905 149.766 46.3519 149.629C46.3519 149.629 43.3141 145.353 43.3795 143.735C43.4449 142.121 39.2537 125.766 39.2537 125.766C39.2537 125.766 38.4749 124.574 37.4226 125.629" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M39.8066 159.473C39.8066 159.473 37.0838 158.382 36.091 157.35C35.1041 156.324 32.5656 154.783 32.1851 153.804C31.8046 152.82 30.2589 153.015 29.177 150.863C28.095 148.71 27.4351 149.511 27.2448 148.668C27.0546 147.826 25.9726 148.603 25.1879 144.66C25.1879 144.66 22.2986 142.827 23.3271 140.437C23.3271 140.437 20.2714 139.038 21.1572 135.432C22.043 131.827 23.9573 128.049 23.9573 128.049C23.9573 128.049 26.258 124.307 27.0665 123.619C27.0665 123.619 29.5099 120.268 30.9129 119.794C32.3219 119.326 32.9223 119.231 33.4395 120.417" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M33.5703 123.228C37.9815 121.953 37.6129 125.884 36.8639 126.732C36.1148 127.575 35.0387 128.939 35.0387 128.939C35.0387 128.939 37.5356 127.664 38.041 129.069C38.9386 131.548 38.142 132.301 37.2087 133.475C36.2753 134.649 33.4158 138.071 33.4158 138.071C33.4158 138.071 37.0303 137.781 34.5988 141.582C33.1423 143.859 34.6107 146.883 34.7296 147.554C35.8235 153.567 41.1442 160.28 41.1442 160.28C62.2845 175.289 78.5916 175.823 86.9027 177.626C95.2137 179.435 97.5263 174.584 97.5263 174.584C97.5263 174.584 99.9103 171.63 103.412 162.486C106.907 153.347 96.4027 118.537 96.4741 116.918C96.5395 115.305 92.4672 90.8011 92.4672 90.8011" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M37.7735 108.111C37.7735 108.111 37.6605 93.9263 42.0122 86.7033" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M58.438 124.147C58.5688 124.532 67.9559 102.169 96.6047 117.689" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M33.5703 123.228L34.5928 107.779L37.5594 107.696L37.2146 124.805" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M58.973 403.118L48.5931 424.573C48.5931 424.573 39.0574 426.922 38.8552 426.963C38.6472 427.005 33.8436 428.28 29.3195 427.592C24.7954 426.91 16.9956 425.071 16.9956 425.071L8.16136 421.27C8.16136 421.27 5.5634 420.126 6.02711 415.808C6.49677 411.497 7.6382 412.049 10.1054 410.691C12.5725 409.327 16.7637 410.068 18.5769 409.647C20.3842 409.226 30.2053 404.843 30.2053 404.843L48.4861 393.896L51.2386 393.339C51.2386 393.339 56.6723 395.604 57.6057 396.399C58.539 397.193 59.5081 397.098 59.7161 398.373" fill="#CCCCCC"/>
            <path d="M69.4601 422.907C55.7985 421.863 51.5538 423.559 48.8845 424.211C44.1583 425.374 39.3666 426.767 36.5428 427.55C33.7249 428.333 24.2129 428.013 10.807 422.836C10.807 422.836 7.21623 421.323 6.31854 419.04C5.41491 416.757 6.78225 413.092 6.78225 413.092C6.78225 413.092 8.45873 410.329 12.751 410.418C17.0492 410.507 23.8205 408.241 26.2223 406.96C28.6241 405.685 47.0297 394.958 49.2293 393.54C50.7512 392.556 53.8783 394.376 55.7391 395.45C57.5998 396.523 58.4916 396.31 59.7341 397.674" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M62.8077 417.825C49.7228 415.678 46.9346 422.889 26.8941 424.656C26.8941 424.656 21.9895 425.024 15.0874 422.272C8.18531 419.521 5.99756 417.214 5.99756 417.214" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M57.6772 396.446C57.6772 396.446 49.0451 394.797 48.3258 401.238C47.7551 406.356 44.7469 399.809 42.5057 405.674C42.0122 406.972 38.4869 403.847 39.1349 408.544C39.3608 410.181 35.1577 406.498 35.6392 411.633C35.7997 413.365 30.9189 408.941 30.6752 413.614C30.6752 413.614 32.0663 414.367 28.1426 414.551C28.1426 414.551 23.6601 407.737 20.3904 408.935" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M58.8007 417.7C58.0636 417.73 50.1151 408.49 51.5954 404.493C53.0757 400.496 55.0316 398.996 57.8436 399.565C60.6556 400.129 59.0861 403.942 59.2704 405.679" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M30.6453 424.241C30.6453 424.241 24.8727 411.989 20.5031 411.058C20.5031 411.058 12.9114 410.347 12.0137 412.06C12.0137 412.06 18.2441 413.928 10.5513 420.214" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.7291 396.244C44.7291 396.244 45.0263 400.971 45.4068 402.868C45.4068 402.868 45.1631 403.556 43.6293 402.952C43.6293 402.952 42.9634 396.606 43.2666 396.084C43.2666 396.084 44.6815 395.562 44.7291 396.244Z" fill="white"/>
            <path d="M44.7291 396.244C44.7291 396.244 45.0263 400.971 45.4068 402.868C45.4068 402.868 45.1631 403.556 43.6293 402.952C43.6293 402.952 42.9634 396.606 43.2666 396.084C43.2666 396.084 44.6815 395.562 44.7291 396.244Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M39.4022 399.257C39.4022 399.257 40.9241 403.8 41.7921 405.567C41.7921 405.567 41.7326 406.296 40.0918 406.065C40.0918 406.065 37.7911 400.028 37.9457 399.447C37.9457 399.447 39.1763 398.605 39.4022 399.257Z" fill="white"/>
            <path d="M39.4022 399.257C39.4022 399.257 40.9241 403.8 41.7921 405.567C41.7921 405.567 41.7326 406.296 40.0918 406.065C40.0918 406.065 37.7911 400.028 37.9457 399.447C37.9457 399.447 39.1763 398.605 39.4022 399.257Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M35.651 402.341C35.651 402.341 37.1729 406.883 38.0409 408.651C38.0409 408.651 37.9814 409.38 36.3406 409.155C36.3406 409.155 34.0399 403.118 34.1945 402.536C34.1945 402.531 35.431 401.688 35.651 402.341Z" fill="white"/>
            <path d="M35.651 402.341C35.651 402.341 37.1729 406.883 38.0409 408.651C38.0409 408.651 37.9814 409.38 36.3406 409.155C36.3406 409.155 34.0399 403.118 34.1945 402.536C34.1945 402.531 35.431 401.688 35.651 402.341Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M31.0792 404.339C31.0792 404.339 32.7617 409.38 33.695 411.343C33.695 411.343 33.6594 412.143 31.9948 411.847C31.9948 411.847 29.4741 405.146 29.6108 404.511C29.6108 404.505 30.8355 403.616 31.0792 404.339Z" fill="white"/>
            <path d="M31.0792 404.339C31.0792 404.339 32.7617 409.38 33.695 411.343C33.695 411.343 33.6594 412.143 31.9948 411.847C31.9948 411.847 29.4741 405.146 29.6108 404.511C29.6108 404.505 30.8355 403.616 31.0792 404.339Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M52.4158 398.409C52.4158 398.409 45.3769 396.387 42.4223 397.015C39.4736 397.65 41.3997 397.721 41.3997 397.721C41.3997 397.721 43.3319 397.946 45.7158 398.587C48.4326 399.316 50.3231 399.927 50.3231 399.927L51.3219 399.785L52.4158 398.409Z" fill="white"/>
            <path d="M52.4158 398.409C52.4158 398.409 52.0947 400.081 50.3231 399.927C48.5515 399.773 43.7183 397.413 40.3594 397.751C40.3594 397.751 41.8932 396.903 43.0108 396.79C43.0108 396.796 50.9771 397.573 52.4158 398.409Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M46.1558 402.442C46.1558 402.442 38.4927 400.686 35.3538 401.427C32.2148 402.169 34.2837 402.169 34.2837 402.169C34.2837 402.169 36.3704 402.317 38.9564 402.869C41.9111 403.491 43.974 404.031 43.974 404.031L45.0381 403.853L46.1558 402.442Z" fill="white"/>
            <path d="M46.1618 402.691C46.1618 402.691 45.8229 404.363 43.9146 404.203C42.0122 404.043 36.8044 401.659 33.1958 401.991C33.1958 401.991 34.8426 401.149 36.0434 401.036C36.0434 401.03 44.6161 401.849 46.1618 402.691Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M41.7624 406.836C41.7624 406.836 34.9137 403.301 31.7332 403.42C29.92 403.485 30.5264 403.9 30.5264 403.9C30.5264 403.9 32.4763 404.517 34.8008 405.632C37.4522 406.907 39.2774 407.897 39.2774 407.897L40.3415 407.963L41.7624 406.836Z" fill="white"/>
            <path d="M41.7626 406.836C41.7626 406.836 41.0789 408.407 39.2835 407.897C37.4881 407.381 33.8022 404.256 30.259 403.9C30.259 403.9 31.2102 403.201 32.3933 403.319C32.3873 403.313 40.4547 405.715 41.7626 406.836Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M38.1538 409.445C38.1538 409.445 30.5026 405.602 26.9832 405.685C24.9678 405.733 25.6515 406.184 25.6515 406.184C25.6515 406.184 27.8214 406.865 30.4194 408.081C33.38 409.475 35.4191 410.548 35.4191 410.548L36.5962 410.631L38.1538 409.445Z" fill="white"/>
            <path d="M38.1538 409.445C38.1538 409.445 37.4167 411.118 35.4132 410.542C33.4098 409.973 29.284 406.599 25.3484 406.178C25.3484 406.178 26.3947 405.442 27.7085 405.579C27.7026 405.579 36.6854 408.241 38.1538 409.445Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M32.8866 412.458C32.8866 412.458 26.9297 407.85 23.8681 406.925C23.1844 406.723 23.2736 407.429 23.2736 407.429C23.2736 407.429 24.3199 408.206 26.3234 409.819C28.6122 411.663 30.1638 413.045 30.1638 413.045L31.1982 413.324L32.8866 412.458Z" fill="white"/>
            <path d="M32.8865 412.458C32.8865 412.458 31.8046 413.94 30.1578 413.045C28.517 412.155 25.5029 409.184 22.9109 407.352C22.9109 407.352 23.4043 406.587 24.5458 406.949C24.5398 406.949 31.8818 411.04 32.8865 412.458Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M89.584 405.458C89.584 405.458 92.3781 409.443 92.7942 413.298C93.2104 417.152 94.7442 422.294 94.7442 422.294L96.2126 427.572L97.3719 432.126C97.3719 432.126 98.2577 435.453 94.6075 436.259C90.9573 437.072 79.5429 437.789 79.5429 437.789L68.3188 437.149L59.8532 436.781C59.8532 436.781 45.288 440.019 45.074 440.049C44.854 440.078 39.7116 441.241 34.9735 440.239C30.2353 439.242 22.0967 436.793 22.0967 436.793L12.983 432.12C12.983 432.12 10.3019 430.715 11.0331 425.97C11.7643 421.226 12.9414 421.896 15.6226 420.521C18.3038 419.145 22.6912 420.183 24.6233 419.815C26.5554 419.447 37.1731 415.118 37.1731 415.118L57.0828 403.981L60.0196 403.507C60.0196 403.507 65.6317 406.3 66.571 407.225C67.5103 408.156 74.2162 412.129 75.5538 412.432C76.5229 412.651 83.7044 416.482 85.5592 410.7L87.3189 406.163C87.3189 406.169 88.6328 404.515 89.584 405.458Z" fill="#CCCCCC"/>
            <path d="M78.7341 413.567C78.7341 413.567 83.5376 414.569 85.3271 410.851C87.1106 407.132 86.4982 403.983 89.2745 405.27C92.0508 406.557 94.2505 420.434 94.6607 423.725C95.0709 427.016 96.1707 426.447 95.8972 428.303C95.6238 430.165 98.9173 432.994 95.487 435.515C92.0568 438.035 70.3933 437.252 62.2665 436.606C55.5309 436.072 50.9176 439.316 42.2142 440.324C39.1287 440.68 29.1709 440.182 15.3013 433.741C15.3013 433.741 11.5916 431.879 10.7712 429.306C9.95081 426.732 11.5916 422.753 11.5916 422.753C11.5916 422.753 13.5118 419.788 18.0479 420.108C22.5779 420.434 29.8546 418.287 32.4644 417C35.0743 415.713 55.1029 404.825 57.4987 403.373C59.1633 402.364 62.3617 404.541 64.2641 405.834C66.1664 407.121 70.3576 409.131 71.5942 410.708C72.8308 412.28 78.0683 413.478 78.7341 413.567Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M95.8974 428.303C95.8974 428.303 96.8427 432.306 71.7252 430.824C46.6077 429.341 53.4444 435.716 32.1911 436.606C32.1911 436.606 26.9893 436.754 19.8553 433.344C12.7214 429.934 10.5396 427.266 10.5396 427.266" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M66.2618 407.043C66.2618 407.043 57.2432 404.76 56.1196 411.847C55.2279 417.481 52.4159 410.074 49.7288 416.443C49.1343 417.854 45.5852 414.213 46.0132 419.438C46.1618 421.258 41.929 416.965 42.149 422.67C42.2203 424.597 37.3157 419.438 36.7985 424.597C36.7985 424.597 37.3216 427.337 30.3304 427.266C23.345 427.194 16.217 422.889 15.4679 422.373C14.7189 421.857 10.819 426.145 11.8831 428.523" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M92.4848 412.244C92.4908 412.262 80.1966 417.344 79.2275 431.126" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M27.5064 418.584C27.5064 418.584 33.5286 423.411 33.3265 424.277C33.1303 425.143 20.3487 422.996 19.0586 420.606" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M52.6002 406.1C52.6002 406.1 52.6478 411.343 52.945 413.466C52.945 413.466 52.6478 414.213 51.0605 413.466C51.0605 413.466 50.7157 406.415 51.0605 405.851C51.0664 405.851 52.5943 405.347 52.6002 406.1Z" fill="white"/>
            <path d="M52.6002 406.1C52.6002 406.1 52.6478 411.343 52.945 413.466C52.945 413.466 52.6478 414.213 51.0605 413.466C51.0605 413.466 50.7157 406.415 51.0605 405.851C51.0664 405.851 52.5943 405.347 52.6002 406.1Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M46.8097 409.149C46.8097 409.149 48.1652 414.255 48.9796 416.253C48.9796 416.253 48.8786 417.06 47.1545 416.716C47.1545 416.716 45.0619 409.914 45.2581 409.285C45.2581 409.285 46.6135 408.413 46.8097 409.149Z" fill="white"/>
            <path d="M46.8097 409.149C46.8097 409.149 48.1652 414.255 48.9796 416.253C48.9796 416.253 48.8786 417.06 47.1545 416.716C47.1545 416.716 45.0619 409.914 45.2581 409.285C45.2581 409.285 46.6135 408.413 46.8097 409.149Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M42.6779 412.363C42.6779 412.363 44.0334 417.469 44.8478 419.467C44.8478 419.467 44.7467 420.274 43.0227 419.93C43.0227 419.93 40.9301 413.128 41.1263 412.499C41.1322 412.499 42.4817 411.628 42.6779 412.363Z" fill="white"/>
            <path d="M42.6779 412.363C42.6779 412.363 44.0334 417.469 44.8478 419.467C44.8478 419.467 44.7467 420.274 43.0227 419.93C43.0227 419.93 40.9301 413.128 41.1263 412.499C41.1322 412.499 42.4817 411.628 42.6779 412.363Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M37.7377 414.326C37.7377 414.326 39.2358 419.989 40.1097 422.213C40.1097 422.213 40.0324 423.097 38.2846 422.676C38.2846 422.676 36.0017 415.126 36.1801 414.438C36.1801 414.438 37.5237 413.513 37.7377 414.326Z" fill="white"/>
            <path d="M37.7377 414.326C37.7377 414.326 39.2358 419.989 40.1097 422.213C40.1097 422.213 40.0324 423.097 38.2846 422.676C38.2846 422.676 36.0017 415.126 36.1801 414.438C36.1801 414.438 37.5237 413.513 37.7377 414.326Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M60.5962 408.906C60.5962 408.906 53.272 406.296 50.1271 406.836C46.9762 407.376 49.0035 407.559 49.0035 407.559C49.0035 407.559 51.0307 407.909 53.5098 408.745C56.3336 409.694 58.3014 410.471 58.3014 410.471L59.3656 410.364L60.5962 408.906Z" fill="white"/>
            <path d="M60.5961 408.906C60.5961 408.906 60.1621 410.738 58.3013 410.477C56.4405 410.21 51.4705 407.346 47.9036 407.542C47.9036 407.542 49.5741 406.682 50.7631 406.616C50.7572 406.616 59.1277 407.903 60.5961 408.906Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M53.7654 413.033C53.7654 413.033 45.7754 410.691 42.4165 411.343C39.0635 412.001 41.2453 412.108 41.2453 412.108C41.2453 412.108 43.439 412.387 46.1439 413.128C49.2234 413.976 51.3755 414.682 51.3755 414.682L52.511 414.539L53.7654 413.033Z" fill="white"/>
            <path d="M53.7534 413.306C53.7534 413.306 53.3016 415.138 51.2981 414.859C49.2947 414.581 43.9323 411.669 40.1038 411.841C40.1038 411.841 41.8872 410.993 43.1654 410.94C43.1654 410.934 52.1661 412.292 53.7534 413.306Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M48.8786 417.653C48.8786 417.653 41.8397 413.383 38.4808 413.341C36.5606 413.318 37.1789 413.81 37.1789 413.81C37.1789 413.81 39.2001 414.593 41.596 415.957C44.3247 417.504 46.1974 418.696 46.1974 418.696L47.315 418.827L48.8786 417.653Z" fill="white"/>
            <path d="M48.8785 417.653C48.8785 417.653 48.07 419.355 46.2033 418.69C44.3366 418.026 40.621 414.373 36.8994 413.792C36.8994 413.792 37.9457 413.069 39.1823 413.264C39.1823 413.264 47.5647 416.354 48.8785 417.653Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.9192 420.351C44.9192 420.351 37.0599 415.69 33.3324 415.595C31.2041 415.541 31.8997 416.075 31.8997 416.075C31.8997 416.075 34.1528 416.941 36.8221 418.429C39.8719 420.126 41.9645 421.418 41.9645 421.418L43.207 421.573L44.9192 420.351Z" fill="white"/>
            <path d="M44.9193 420.351C44.9193 420.351 44.0513 422.16 41.9706 421.418C39.8898 420.677 35.7165 416.728 31.5847 416.057C31.5847 416.057 32.7321 415.298 34.1113 415.524C34.1054 415.524 43.439 418.945 44.9193 420.351Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M39.1941 423.399C39.1941 423.399 33.16 417.991 29.9794 416.805C29.272 416.538 29.3195 417.332 29.3195 417.332C29.3195 417.332 30.3777 418.246 32.4109 420.137C34.7235 422.296 36.2811 423.915 36.2811 423.915L37.3571 424.277L39.1941 423.399Z" fill="white"/>
            <path d="M39.1942 423.399C39.1942 423.399 37.9636 424.982 36.2812 423.909C34.5987 422.836 31.5846 419.39 28.9451 417.232C28.9451 417.232 29.5098 416.413 30.6869 416.87C30.6869 416.87 38.2133 421.786 39.1942 423.399Z" stroke="#8C8C8C" stroke-width="1.22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M113.167 438H107.078C106.219 438 105.515 437.309 105.503 436.449L103.664 323.549H116.587L114.748 436.449C114.73 437.309 114.027 438 113.167 438ZM214.526 341.594H116.581V323.543H214.526V341.594ZM214.526 313.908H105.179C102.979 313.908 101.2 315.687 101.2 317.887V319.57C101.2 321.77 102.979 323.549 105.179 323.549H214.526V313.908ZM228.423 437.062C229.349 437.062 230.076 436.275 229.992 435.349C226.656 397.518 225.201 356.206 225.321 311.859C225.436 270.318 227.443 231.477 230.617 195.713C230.701 194.787 229.974 194 229.048 194H221.427C220.609 194 219.924 194.619 219.852 195.437C216.666 231.279 214.646 270.21 214.532 311.859C214.412 356.314 217.694 397.728 221.09 435.632C221.162 436.443 221.841 437.068 222.659 437.068H228.423V437.062Z" stroke="#8C8C8C" stroke-width="1.35488" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg ref="graphic-3" display="none" width="100%" height="100%" viewBox="0 0 241 447" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M112.814 438H106.725C105.866 438 105.162 437.309 105.15 436.449L103.311 323.549H116.234L114.395 436.449C114.377 437.309 113.674 438 112.814 438ZM214.173 341.594H116.228V323.543H214.173V341.594ZM214.173 313.908H104.826C102.626 313.908 100.847 315.687 100.847 317.887V319.57C100.847 321.77 102.626 323.549 104.826 323.549H214.173V313.908ZM228.07 437.062C228.996 437.062 229.723 436.275 229.639 435.349C226.303 397.518 224.848 356.206 224.968 311.859C225.083 270.318 227.09 231.477 230.264 195.713C230.348 194.787 229.621 194 228.695 194H221.073C220.256 194 219.571 194.619 219.499 195.437C216.313 231.279 214.293 270.21 214.179 311.859C214.059 356.314 217.341 397.728 220.737 435.632C220.809 436.443 221.488 437.068 222.306 437.068H228.07V437.062Z" stroke="#8C8C8C" stroke-width="1.35488" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1738 429.194L15.0715 430.059L15.7702 433.842L18.7175 437.464L22.1675 438.754L24.0461 439.449L28.3434 438.589L30.6073 440.156L35.1032 439.508L36.653 440.855L41.3566 440.46L44.7291 440.764L49.5943 440.292L58.0453 438.793L71.681 435.515C71.681 435.515 76.5855 434.798 76.8903 434.811C81.9958 435.026 86.3644 434.861 86.3644 434.861L90.4228 431.747L91.1043 427.194L90.3423 419.61L88.2221 410.745L86.9992 402.745L87.7871 392.253L90.1966 381.691L79.0719 378.314L65.7268 382.427L64.7024 392.332L61.6267 402.196L58.0453 410.916L53.8278 413.169L49.5575 415.573L43.4079 419.191L37.8065 421.461L32.9069 423.281L29.8281 425.365L27.0794 426.847L23.1931 427.837L21.1738 429.194Z" fill="#FAFAFA"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0528 418.677L11.4438 419.698L11.4684 421.588L12.4197 424.719L16.6673 426.847L19.7449 427.195L24.0416 426.335L26.3055 427.903L30.8014 427.254L32.3512 428.602L37.0554 428.206L40.4279 428.51L45.2925 428.039L53.7441 426.539L67.3798 423.262C67.3798 423.262 72.2837 422.544 72.5891 422.557C77.694 422.772 82.0632 422.608 82.0632 422.608L86.1209 419.494L86.8025 414.94L86.0404 407.357L83.9209 398.492L82.698 390.491L83.4859 380L85.8954 369.437L74.7701 366.061L61.4256 370.173L60.4012 380.078L59.6841 389.983L56.5094 399.276L53.0884 401.824L48.6226 403.972L42.7447 406.758L37.2539 410.099L33.6694 412.448L29.5723 414.184L23.837 417.35L20.969 417.451L16.0528 418.677Z" fill="#FAFAFA"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.9028 173.839C32.9028 173.839 27.3703 211.387 28.4457 222.416C29.3902 232.107 28.2668 277.403 28.2668 277.403C28.2668 277.403 36.9464 278.373 40.2753 278.782L40.3263 287.768C40.3263 287.768 65.1125 295.12 105.671 286.542L111.303 241.417L113.581 196.481V170.727C113.581 170.727 88.3619 179.069 32.9028 173.839Z" fill="#CCCCCC"/>
            <path d="M49.7072 289.859C49.7072 289.859 52.1045 303.748 58.0961 313.398C58.0961 313.398 58.7106 316.461 59.0179 317.84C59.3252 319.218 59.7861 322.282 60.5542 324.426C61.3224 326.571 60.953 340.203 64.902 367.467C68.8503 394.731 58.4034 407.291 58.0961 410.66C58.0961 410.66 51.49 415.255 48.4173 416.021C45.3447 416.787 43.1939 419.697 41.043 420.463C38.8922 421.229 32.1324 422.761 29.828 425.365C27.5235 427.968 25.219 425.824 21.5319 429.194C21.5319 429.194 15.233 428.428 15.0794 431.185C14.9257 433.942 17.0766 435.321 17.8447 435.474C18.6129 435.627 17.2302 438.384 21.8391 438.384C21.8391 438.384 23.3754 440.988 28.7525 438.384C28.7525 438.384 28.5989 441.617 35.3587 439.541C35.3587 439.541 35.6659 442.395 41.8112 440.236C41.8112 440.236 44.1157 441.447 49.8 440.222C55.4844 438.997 58.125 439.762 65.7924 436.852C73.4592 433.942 82.0626 435.474 86.3643 434.861C90.6659 434.248 90.9732 428.122 91.2805 425.671C91.5877 423.22 87.747 409.435 87.747 408.516C87.747 407.597 84.9816 400.704 89.8978 382.784C94.814 364.863 101.267 338.211 93.4313 320.291C93.4313 320.291 87.6861 303.874 88.3615 297.315C89.1296 289.859 88.6687 290.729 88.6687 290.729" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M43.8086 288.738C43.8086 288.738 47.3422 299.766 50.2611 306.199C50.2611 306.199 51.4902 311.407 52.1047 315.236C52.1047 315.236 53.4874 340.203 59.6327 369.152C59.6327 369.152 63.4734 392.586 54.2556 401.317C54.2556 401.317 39.8142 407.444 34.1299 412.039C34.1299 412.039 26.9092 415.715 23.5293 417.553C23.5293 417.553 19.074 417.553 16.9232 418.778C16.9232 418.778 11.2388 418.931 11.2388 420.463C11.2388 421.995 10.4553 424.599 15.0716 426.13C19.6885 427.662 22.3003 426.284 23.8366 426.13C25.3729 425.977 27.0794 426.847 27.0794 426.847" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M113.455 171.563C113.455 171.563 115.709 227.113 105.671 286.543C105.671 286.543 78.4272 295.12 40.326 287.768L40.275 278.782C40.275 278.782 30.5201 278.629 28.2666 277.403C28.2666 277.403 28.5898 227.42 28.4454 222.416C28.2666 216.228 31.059 176.29 32.9026 173.839" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M104.237 9C104.851 9.20402 132.3 51.8009 104.237 112.091C104.237 112.091 100.14 123.162 104.237 141.747C108.334 160.331 115.299 170.134 115.299 170.134C115.299 170.134 92.2079 179.733 28.269 173.401C28.269 173.401 38.4585 111.942 37.2294 97.2376" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M40.2839 281.539C40.2839 281.539 78.1694 290.53 107.002 279.448" stroke="#8C8C8C" stroke-width="0.612" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1.22"/>
            <path d="M28.2671 272.862C28.2671 272.862 33.8132 274.66 39.8355 274.272" stroke="#8C8C8C" stroke-width="0.612" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1.22"/>
            <path d="M40.2752 278.782L34.0476 215.622" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M56.3582 36.5701C56.3582 36.5701 56.8694 51.4943 57.8392 56.2591C58.7204 60.5889 54.9226 66.7757 59.7571 76.2881C59.7571 76.2881 57.9258 82.6967 58.2577 84.4581" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M58.2575 84.4578C58.2575 84.4578 54.0879 83.673 49.3899 84.1203C44.6918 84.5675 39.4905 78.0535 37.0987 76.9777C37.0987 76.9777 35.7781 71.724 36.4492 70.1935C37.1203 68.6631 39.2219 51.3482 39.2219 51.3482C39.2219 51.3482 38.9208 49.9103 37.5092 50.5334" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M27.0916 84.0148C27.0916 84.0148 24.8768 81.9434 24.3109 80.5851C23.7449 79.2268 21.8773 76.7969 21.8816 75.7094C21.8872 74.6219 20.3232 74.228 20.0866 71.7509C19.8494 69.2745 18.919 69.7977 19.0518 68.9154C19.1857 68.0332 17.8479 68.3787 18.5773 64.2885C18.5773 64.2885 16.4837 61.4451 18.3709 59.5268C18.3709 59.5268 15.9533 57.0406 18.1588 53.8969C20.3644 50.7527 23.6269 47.8345 23.6269 47.8345C23.6269 47.8345 27.2471 45.0872 28.2887 44.7288C28.2887 44.7288 31.9002 42.4141 33.4329 42.4858C34.9655 42.5569 35.5831 42.6935 35.6329 44.0298" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M34.6976 46.781C39.4245 47.2 37.5938 50.8516 36.5553 51.3852C35.5167 51.9189 33.9688 52.8268 33.9688 52.8268C33.9688 52.8268 36.8509 52.5291 36.8128 54.0773C36.747 56.8001 35.698 57.2259 34.3559 58.0052C33.0132 58.7851 28.9763 61.0171 28.9763 61.0171C28.9763 61.0171 32.567 62.0874 28.7975 64.8365C26.5379 66.4846 26.8157 69.9481 26.6823 70.6343C25.4791 76.8303 28.0853 85.2852 28.0853 85.2852C42.813 107.633 58.3224 114.238 65.6531 119.082C72.9831 123.927 77.0322 120.118 77.0322 120.118C77.0322 120.118 80.4416 118.164 87.245 110.671C94.0472 103.178 97.0018 65.7347 97.6723 64.2036C98.3427 62.6726 103.622 37.559 103.622 37.559" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.4189 33.7945C44.4189 33.7945 55.1559 17.9942 62.057 12.6652" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M58.3096 56.9535C58.2893 57.372 75.7314 39.3483 97.504 64.9937" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M34.6975 46.781L41.4825 32.2875L44.3732 33.3162L37.6116 49.663" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M75.7482 428.278L62.6773 427.573L50.235 429.456C50.235 429.456 40.7867 431.785 40.5851 431.824C40.3811 431.863 35.6216 433.127 31.1386 432.449C26.6557 431.771 18.9243 429.95 18.9243 429.95L10.1704 426.189C10.1704 426.189 7.59432 425.054 8.05521 420.781C8.51856 416.51 9.64929 417.059 12.0957 415.709C14.5421 414.36 18.6963 415.093 20.4883 414.675C22.2802 414.256 32.0112 409.914 32.0112 409.914L50.1287 399.075L52.8535 398.519C52.8535 398.519 58.2398 400.762 59.1628 401.549" fill="#CCCCCC"/>
            <path d="M70.9136 427.806C57.3744 426.773 53.1717 428.453 50.5231 429.1C45.8392 430.248 41.0901 431.631 38.294 432.409C35.5022 433.184 26.0773 432.866 12.79 427.738C12.79 427.738 9.2313 426.241 8.33962 423.978C7.4461 421.716 8.80052 418.09 8.80052 418.09C8.80052 418.09 10.4597 415.354 14.7172 415.438C18.974 415.525 25.6852 413.281 28.0665 412.012C30.4484 410.747 48.6838 400.122 50.8641 398.718C52.374 397.746 55.47 399.545 57.313 400.608C59.1565 401.675 59.6045 402.457 60.836 403.808" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M64.324 422.773C51.3587 420.644 48.5928 427.787 28.7368 429.538C28.7368 429.538 23.8741 429.903 17.0357 427.177C10.1954 424.451 8.02734 422.165 8.02734 422.165" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M59.2365 401.603C59.2365 401.603 50.6854 399.97 49.9695 406.348C49.4023 411.42 46.4206 404.931 44.2046 410.739C43.7142 412.025 40.2213 408.932 40.8672 413.582C41.0921 415.203 36.9268 411.556 37.4037 416.642C37.5635 418.359 32.7284 413.974 32.4832 418.604C32.4832 418.604 33.8597 419.35 29.971 419.534C29.971 419.534 25.5261 412.787 22.2876 413.974" stroke="#8C8C8C" stroke-width="0.612" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M60.3501 422.653C59.6188 422.68 51.7455 413.534 53.21 409.576C54.6744 405.616 56.6132 404.132 59.4019 404.697C62.1882 405.256 71.4196 417.215 67.824 422.606" stroke="#8C8C8C" stroke-width="0.612" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M32.4515 429.129C32.4515 429.129 26.7303 416.996 22.3997 416.075C22.3997 416.075 14.8755 415.371 13.9887 417.067C13.9887 417.067 20.1641 418.917 12.5427 425.141" stroke="#8C8C8C" stroke-width="0.612" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.4067 401.401C46.4067 401.401 46.7011 406.08 47.0815 407.962C47.0815 407.962 46.8369 408.643 45.3178 408.047C45.3178 408.047 44.6609 401.762 44.9589 401.246C44.9589 401.246 46.3625 400.727 46.4067 401.401Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.4067 401.401C46.4067 401.401 46.7011 406.08 47.0815 407.962C47.0815 407.962 46.8369 408.643 45.3178 408.047C45.3178 408.047 44.6609 401.762 44.9589 401.246C44.9589 401.246 46.3625 400.727 46.4067 401.401Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.1305 404.385C41.1305 404.385 42.6379 408.883 43.4982 410.633C43.4982 410.633 43.4417 411.354 41.812 411.128C41.812 411.128 39.5302 405.149 39.6845 404.576C39.6845 404.576 40.908 403.738 41.1305 404.385Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.1305 404.385C41.1305 404.385 42.6379 408.883 43.4982 410.633C43.4982 410.633 43.4417 411.354 41.812 411.128C41.812 411.128 39.5302 405.149 39.6845 404.576C39.6845 404.576 40.908 403.738 41.1305 404.385Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4137 407.439C37.4137 407.439 38.9236 411.938 39.7827 413.686C39.7827 413.686 39.7267 414.409 38.0958 414.183C38.0958 414.183 35.8141 408.203 35.9671 407.63C35.9671 407.63 37.1931 406.792 37.4137 407.439Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4137 407.439C37.4137 407.439 38.9236 411.938 39.7827 413.686C39.7827 413.686 39.7267 414.409 38.0958 414.183C38.0958 414.183 35.8141 408.203 35.9671 407.63C35.9671 407.63 37.1931 406.792 37.4137 407.439Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.8802 409.418C32.8802 409.418 34.5499 414.408 35.4717 416.353C35.4717 416.353 35.4391 417.148 33.7872 416.85C33.7872 416.85 31.2917 410.21 31.4275 409.586C31.4275 409.586 32.6375 408.7 32.8802 409.418Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.8802 409.418C32.8802 409.418 34.5499 414.408 35.4717 416.353C35.4717 416.353 35.4391 417.148 33.7872 416.85C33.7872 416.85 31.2917 410.21 31.4275 409.586C31.4275 409.586 32.6375 408.7 32.8802 409.418Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.0223 403.548C54.0223 403.548 47.0468 401.548 44.1211 402.17C41.1996 402.797 43.1071 402.87 43.1071 402.87C43.1071 402.87 45.0208 403.09 47.3811 403.725C50.0709 404.446 51.9483 405.055 51.9483 405.055L52.9389 404.911L54.0223 403.548Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.0224 403.548C54.0224 403.548 53.7023 405.205 51.9484 405.055C50.1945 404.9 45.4043 402.562 42.0754 402.9C42.0754 402.9 43.5964 402.059 44.705 401.948C44.705 401.948 52.6016 402.72 54.0224 403.548Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.8237 407.541C47.8237 407.541 40.2313 405.804 37.1199 406.538C34.0104 407.274 36.0611 407.275 36.0611 407.275C36.0611 407.275 38.129 407.424 40.694 407.968C43.6198 408.589 45.6649 409.122 45.6649 409.122L46.7225 408.943L47.8237 407.541Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.8246 407.783C47.8246 407.783 47.489 409.441 45.5987 409.279C43.7128 409.119 38.5557 406.761 34.9773 407.086C34.9773 407.086 36.6076 406.251 37.8004 406.142C37.8004 406.142 46.2938 406.95 47.8246 407.783Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.4673 411.89C43.4673 411.89 36.6792 408.391 33.5304 408.505C31.7317 408.566 32.337 408.981 32.337 408.981C32.337 408.981 34.2678 409.59 36.5717 410.697C39.2006 411.958 41.0104 412.938 41.0104 412.938L42.0637 413.002L43.4673 411.89Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.4673 411.89C43.4673 411.89 42.7913 413.448 41.0104 412.938C39.2295 412.427 35.5774 409.33 32.0679 408.981C32.0679 408.981 33.0118 408.285 34.18 408.404C34.18 408.404 42.1725 410.785 43.4673 411.89Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.8892 414.476C39.8892 414.476 32.3072 410.667 28.8167 410.751C26.8189 410.795 27.4979 411.242 27.4979 411.242C27.4979 411.242 29.6488 411.916 32.2206 413.122C35.1518 414.5 37.1749 415.563 37.1749 415.563L38.3437 415.644L39.8892 414.476Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.8892 414.476C39.8892 414.476 39.1591 416.129 37.1748 415.563C35.1924 414.999 31.099 411.656 27.198 411.241C27.198 411.241 28.2384 410.51 29.5369 410.649C29.5369 410.649 38.437 413.282 39.8892 414.476Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M34.6692 417.458C34.6692 417.458 28.7691 412.896 25.7309 411.977C25.0555 411.775 25.1385 412.477 25.1385 412.477C25.1385 412.477 26.1733 413.244 28.1638 414.846C30.4332 416.67 31.9671 418.041 31.9671 418.041L32.9909 418.32L34.6692 417.458Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M34.6693 417.458C34.6693 417.458 33.5945 418.926 31.9672 418.041C30.3406 417.16 27.3558 414.217 24.7847 412.406C24.7847 412.406 25.2751 411.648 26.4015 412.004C26.4015 412.004 33.6775 416.058 34.6693 417.458Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M90.7344 406.032C90.7344 406.032 93.622 410.147 94.0528 414.129C94.4842 418.112 96.0672 423.424 96.0672 423.424L97.5839 428.879L98.7798 433.583C98.7798 433.583 99.6942 437.017 95.9228 437.854C92.1521 438.692 80.3502 439.434 80.3502 439.434L68.7485 438.773L60.426 438.366C60.426 438.366 44.9431 441.733 44.7188 441.768C44.4932 441.802 39.1782 442.997 34.2798 441.966C29.3833 440.937 20.9717 438.408 20.9717 438.408L11.5498 433.583C11.5498 433.583 8.77948 432.131 9.53657 427.228C10.2943 422.324 11.5086 423.021 14.2813 421.6C17.0541 420.18 21.5862 421.252 23.5834 420.869C25.5806 420.486 36.5542 416.014 36.5542 416.014L57.1377 404.511L60.1704 404.022C60.1704 404.022 65.9709 406.907 66.9431 407.865C67.914 408.825 74.8458 412.93 76.2285 413.242C77.2327 413.468 84.6518 417.424 86.5722 411.45L88.3937 406.761C88.3937 406.761 89.7468 405.056 90.7344 406.032Z" fill="#CCCCCC"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8387 414.606C79.8387 414.606 84.8053 415.639 86.6514 411.797C88.4962 407.954 87.8626 404.701 90.7343 406.032C93.6053 407.363 95.8766 421.7 96.3013 425.1C96.7271 428.501 97.8628 427.91 97.5795 429.831C97.2949 431.752 100.701 434.677 97.1536 437.28C93.6053 439.883 71.2176 439.076 62.8109 438.408C55.8465 437.854 51.079 441.21 42.0854 442.248C38.8972 442.617 28.6021 442.101 14.2671 435.449C14.2671 435.449 10.4349 433.527 9.58377 430.865C8.73265 428.205 10.4349 424.096 10.4349 424.096C10.4349 424.096 12.4223 421.03 17.1056 421.365C21.7889 421.7 29.3125 419.483 32.0078 418.152C34.7049 416.823 55.4071 405.575 57.8885 404.074C59.6073 403.035 62.9129 405.284 64.88 406.614C66.8471 407.945 71.1801 410.023 72.4583 411.649C73.7353 413.275 79.1474 414.514 79.8387 414.606Z" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M97.5795 429.83C97.5795 429.83 98.5553 433.965 72.5917 432.433C46.6286 430.901 53.6956 437.488 31.7257 438.407C31.7257 438.407 26.3486 438.56 18.9743 435.037C11.6 431.514 9.34473 428.757 9.34473 428.757" stroke="#8C8C8C" stroke-width="1.224" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M66.9433 407.865C66.9433 407.865 57.6197 405.507 56.4607 412.828C55.5389 418.648 52.6334 410.996 49.8545 417.576C49.24 419.031 45.5738 415.27 46.0138 420.671C46.1674 422.554 41.7926 418.116 42.0193 424.009C42.0962 426 37.0263 420.671 36.4886 426C36.4886 426 37.0263 428.834 29.8057 428.757C22.5844 428.681 15.2174 424.239 14.4419 423.703C13.667 423.167 9.63878 427.601 10.7345 430.055" stroke="#8C8C8C" stroke-width="0.612" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M94.0531 413.241C94.0581 413.259 81.3485 418.513 80.3499 432.749" stroke="#8C8C8C" stroke-width="0.612" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.8855 419.792C26.8855 419.792 33.11 424.781 32.9047 425.671C32.6995 426.563 19.4866 424.348 18.1555 421.881" stroke="#8C8C8C" stroke-width="0.612" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.8255 406.892C52.8255 406.892 52.8759 412.308 53.1832 414.5C53.1832 414.5 52.8771 415.274 51.2376 414.5C51.2376 414.5 50.8799 407.214 51.2376 406.634C51.2376 406.634 52.8144 406.113 52.8255 406.892Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.8255 406.892C52.8255 406.892 52.8759 412.308 53.1832 414.5C53.1832 414.5 52.8771 415.274 51.2376 414.5C51.2376 414.5 50.8799 407.214 51.2376 406.634C51.2376 406.634 52.8144 406.113 52.8255 406.892Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.8386 410.043C46.8386 410.043 48.2378 415.319 49.0828 417.383C49.0828 417.383 48.9783 418.214 47.1974 417.862C47.1974 417.862 45.0331 410.836 45.2353 410.183C45.2353 410.183 46.6333 409.286 46.8386 410.043Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.8386 410.043C46.8386 410.043 48.2378 415.319 49.0828 417.383C49.0828 417.383 48.9783 418.214 47.1974 417.862C47.1974 417.862 45.0331 410.836 45.2353 410.183C45.2353 410.183 46.6333 409.286 46.8386 410.043Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42.5679 413.361C42.5679 413.361 43.9684 418.637 44.8122 420.701C44.8122 420.701 44.7077 421.532 42.9281 421.18C42.9281 421.18 40.7625 414.155 40.9659 413.501C40.9659 413.501 42.3639 412.604 42.5679 413.361Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42.5679 413.361C42.5679 413.361 43.9684 418.637 44.8122 420.701C44.8122 420.701 44.7077 421.532 42.9281 421.18C42.9281 421.18 40.7625 414.155 40.9659 413.501C40.9659 413.501 42.3639 412.604 42.5679 413.361Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4607 415.393C37.4607 415.393 39.0081 421.243 39.9108 423.54C39.9108 423.54 39.8279 424.453 38.0261 424.018C38.0261 424.018 35.6639 416.221 35.8501 415.506C35.8501 415.506 37.2352 414.553 37.4607 415.393Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4607 415.393C37.4607 415.393 39.0081 421.243 39.9108 423.54C39.9108 423.54 39.8279 424.453 38.0261 424.018C38.0261 424.018 35.6639 416.221 35.8501 415.506C35.8501 415.506 37.2352 414.553 37.4607 415.393Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M61.0872 409.792C61.0872 409.792 53.5193 407.097 50.2629 407.655C47.0078 408.215 49.1033 408.404 49.1033 408.404C49.1033 408.404 51.1988 408.766 53.7614 409.629C56.6829 410.611 58.7133 411.413 58.7133 411.413L59.8139 411.304L61.0872 409.792Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M61.0873 409.792C61.0873 409.792 60.6375 411.686 58.7134 411.413C56.7899 411.139 51.6525 408.176 47.9653 408.383C47.9653 408.383 49.6903 407.496 50.9194 407.428C50.9194 407.428 59.57 408.758 61.0873 409.792Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.0256 414.055C54.0256 414.055 45.7658 411.633 42.2968 412.307C38.8291 412.984 41.088 413.099 41.088 413.099C41.088 413.099 43.3569 413.385 46.1511 414.156C49.3362 415.032 51.5565 415.761 51.5565 415.761L52.7321 415.612L54.0256 414.055Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.0149 414.335C54.0149 414.335 53.5466 416.228 51.4775 415.939C49.4084 415.649 43.8642 412.643 39.9048 412.821C39.9048 412.821 41.7508 411.947 43.0708 411.888C43.0708 411.888 52.3766 413.288 54.0149 414.335Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.9775 418.831C48.9775 418.831 41.7027 414.42 38.23 414.376C36.2439 414.35 36.8873 414.86 36.8873 414.86C36.8873 414.86 38.9767 415.669 41.452 417.074C44.2727 418.675 46.2096 419.904 46.2096 419.904L47.3656 420.037L48.9775 418.831Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.9773 418.831C48.9773 418.831 48.1409 420.591 46.2095 419.904C44.2787 419.216 40.4366 415.443 36.5903 414.844C36.5903 414.844 37.6707 414.095 38.9526 414.296C38.9526 414.296 47.6149 417.485 48.9773 418.831Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M44.8833 421.618C44.8833 421.618 36.7562 416.804 32.9068 416.705C30.7043 416.648 31.4258 417.202 31.4258 417.202C31.4258 417.202 33.753 418.097 36.5159 419.633C39.6672 421.383 41.8321 422.724 41.8321 422.724L43.1153 422.882L44.8833 421.618Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M44.8834 421.618C44.8834 421.618 43.9831 423.486 41.8323 422.724C39.6815 421.96 35.3675 417.877 31.0972 417.182C31.0972 417.182 32.2832 416.397 33.7064 416.63C33.7064 416.63 43.3545 420.162 44.8834 421.618Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M38.9633 424.767C38.9633 424.767 32.7283 419.182 29.4357 417.951C28.7038 417.679 28.7554 418.495 28.7554 418.495C28.7554 418.495 29.8511 419.438 31.9491 421.395C34.3421 423.628 35.9521 425.295 35.9521 425.295L37.0632 425.671L38.9633 424.767Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M38.9635 424.767C38.9635 424.767 37.6927 426.403 35.9524 425.295C34.212 424.185 31.097 420.625 28.3716 418.394C28.3716 418.394 28.9535 417.547 30.1728 418.019C30.1728 418.019 37.952 423.1 38.9635 424.767Z" stroke="#8C8C8C" stroke-width="1.22" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    </div>
    

</template>

<script>
import gsap from "gsap";

export default {
    name: "SitToStandAnimation",
    mounted() {
        const {$refs} = this;

        let movementTime = 0.5
        
        // graphic-1 = sitting
        // graphic-2 = halfway up
        // graphic-3 = standing

        let tl = gsap.timeline({repeat: -1, repeatDelay: movementTime});
        // tl.to('.green-check', {display: 'initial', delay: 2, duration: 0})
        tl.to($refs['graphic-1'], {display: 'none', duration: 0, delay: movementTime})
        tl.to($refs['graphic-2'], {display: 'initial', duration: 0, delay: 0})
        tl.to($refs['graphic-2'], {display: 'none', duration: 0, delay: movementTime})
        tl.to($refs['graphic-3'], {display: 'initial', duration: 0, delay: 0})
        tl.to($refs['graphic-3'], {display: 'none', duration: 0, delay: movementTime})
        tl.to($refs['graphic-2'], {display: 'initial', duration: 0, delay: 0})
        tl.to($refs['graphic-2'], {display: 'none', duration: 0, delay: movementTime})
        tl.to($refs['graphic-1'], {display: 'initial', duration: 0, delay: 0})
        
    }
}
</script>

<style scoped>

</style>