<template>
    <div>
        <div
            v-for="(section, sectionIndex) in sections"
            :key="sectionIndex"
            style="margin-bottom: 30px; margin-top: 20px"
        >


            <div class="section-title animate" v-html="section.title">
                <!-- {{section.title}} -->
            </div>
            <div class="section-description animate" v-html="section.description">
            </div>
            <div class="animate" style="width: 100%; display: flex; justify-content: center; margin-top: 20px">
                <component v-if="section.component" :is="section.component" style="height: 300px" ></component>
                <img v-else-if="section.img" :src="section.img" alt="" style="max-width: 80vw; max-height: 400px; min-height: 200px; height: 400px">
                <iframe v-if="section.youtubeUrl" width="100%" style="min-height: 200px" :src="section.youtubeUrl"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import QRCodePic from "@/assets/images/versions/550/QRCode_iphone13.png";
import CodeNotificationsPic from "@/assets/images/versions/550/Code_Notifications_Screenshot_iphone13.png";
import TimezonePic from "@/assets/images/versions/550/Timezone_550_Screenshot_iphone13.png";
import mCTSIBPic from "@/assets/images/versions/550/mctsib_norms_screenshot_iphone13.png";

import SitToStandAnimation from "@/components/Release520/SitToStandAnimation";

export default {
    name: "Release550Sections",
    components: {SitToStandAnimation},
    data() {
        return {
            sections: [
                {
                    title: '<div>Share Codes via QR Code or SMS/Email</div>',
                    description: `<div><p>Easily share your group or profile codes by having your patients or athletes simply scan the generated QR code. Or click the share icon to easily email or text the code to your participants. 
Athletes must have the Sway Medical app installed and they will automatically enter the testing process as if they manually entered the code.</p></div>`,
                    // youtubeUrl: 'https://www.youtube.com/embed/rOECayR31fw',
	                img: QRCodePic
                    // component: SitToStandAnimation
                },
                {
                    title: '<div>Notify Administrators When a Code is Used</div>',
                    description: `<div><p>Notify your administrators via SMS, Email, or App Notification when a test is completed on any given profile or group code. This is configured separately for each code.
                        
                    </p></div>`,
	                img: CodeNotificationsPic
                    // component: SitToStandAnimation
                },
                {
                    title: '<div>Time Zones!</div>',
                    description: `<div><p>All dates and times are now displayed in the timezone of the device. This includes the date and time of the test, the date and time of the test results, and the date and times of codes.
                        
                    </p></div>`,
                    img: TimezonePic
                    // component: SitToStandAnimation
                },
                {
                    title: '<div>mCTSIB Normative Data</div>',
                    description: `<div><p>Normative Data for mCTSIB scores has been added to the app. Please contact <a>sales@swaymedical.com</a> if you would like access to the mCTSIB Test Type.
                        
                    </p></div>`,
                    img: mCTSIBPic
                    // component: SitToStandAnimation
                },
                {
                    title: 'Other Updates',
                    description: `<div>
                    <ul>
                        <li><strong>Increased minimum iOS version to 13+.</strong></li>
                        <li>Display more metadata in test results such as app version and platform.</li>
                        <li>Various bug fixes.</li>
                    </ul>
                    </div>`,
                },
            ]
        }
    }
}
</script>

<style scoped>
.section-title {
    font-weight: 900;
    margin: 20px 0;
    font-size: 16px;
    color: #333
}

.section-description {
    font-size: 14px;
    color: #757575
}
</style>