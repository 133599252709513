import Vue from 'vue'
import VueRouter from 'vue-router'
import Release500 from "@/views/Versions/Release500";
import About from "@/views/About";
import Help from "@/views/Help";
import Mobile from "@/views/Mobile";
import Terms from "@/views/Terms";
import PleaseUpdate from "@/views/PleaseUpdate";
import Release520 from "@/views/Versions/Release520";
import Release521 from "@/views/Versions/Release521";
import ReleaseIndex from "@/views/Versions/index";
import Release520Update from "@/views/Versions/Release520Update";
import Release530 from "@/views/Versions/Release530";
import Release540 from "@/views/Versions/Release540";
import Release540Sections from "@/views/Versions/Release540Sections";
import Release550 from "@/views/Versions/Release550";
import Release550Sections from "@/views/Versions/Release550Sections";
import OrganizationTerms from "@/views/OrganizationTerms.vue";
import EndUserTerms from "@/views/EndUserTerms.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Release500
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: Mobile,
    children: [
      {
        path: 'about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: About
      },
      {
        path: 'help',
        name: 'Help',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Help
      },
      {
        path: 'terms',
        name: 'Terms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Terms
      },
      {
        path: 'organization-terms',
        name: 'OrganizationTerms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: OrganizationTerms
      },
      {
        path: 'end-user-terms',
        name: 'EndUserTerms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: EndUserTerms
      },
      {
        path: 'pleaseupdate',
        name: 'Please Update',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: PleaseUpdate
      },
      {
        path: 'releases',
        name: 'Releases',
        component: ReleaseIndex,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        children: [
          {
            path: '500',
            name: 'Release500',
            component: Release500
          },
          {
            path: '520-update',
            name: 'Release520Update',
            component: Release520Update
          },
          {
            path: '520',
            name: 'Release520',
            component: Release520
          },
          {
            path: '521',
            name: 'Release521',
            component: Release521
          },
          {
            path: '530',
            name: 'Release530',
            component: Release530
          },
          {
            path: '530-update',
            name: 'Release530',
            component: Release530
          },
            {
            path: '540',
            name: 'Release540',
            component: Release540
          },
          {
            path: '540-update',
            name: 'Release540',
            component: Release540Sections
          },
          {
            path: '550',
            name: 'Release550',
            component: Release550
          },
          {
            path: '550-update',
            name: 'Release550',
            component: Release550Sections
          },
            
        ]
      }
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
