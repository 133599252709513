<template>
    <div>
        <div
            v-for="(section, sectionIndex) in sections"
            :key="sectionIndex"
            style="margin-bottom: 30px; margin-top: 20px"
        >


            <div class="section-title animate" v-html="section.title">
                <!-- {{section.title}} -->
            </div>
            <div class="section-description animate" v-html="section.description">
            </div>
            <div class="animate" style="width: 100%; display: flex; justify-content: center; margin-top: 20px">
                <component v-if="section.component" :is="section.component" style="height: 300px" ></component>
                <img v-else-if="section.img" :src="section.img" alt="" style="max-width: 80vw; max-height: 400px; min-height: 200px; height: 400px">
                <iframe v-if="section.youtubeUrl" width="100%" style="min-height: 200px" :src="section.youtubeUrl"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import baselining from "@/assets/images/versions/500/Baseline_V1.png";
import norms from "@/assets/images/versions/500/normative-data.png";
import codeShowResults from "@/assets/images/versions/530/code-show-results.png";
import cuedStroopDarkTheme from "@/assets/images/versions/530/cued-stroop-dark-theme.png";

import SitToStandAnimation from "@/components/Release520/SitToStandAnimation";

export default {
    name: "Release530Sections",
    components: {SitToStandAnimation},
    data() {
        return {
            sections: [
                {
                    title: '<div>Overview Video</div>',
                    description: `<div><p>Watch this 9-minute video to get an overview of the major updates included in 5.3.</p></div>`,
                    youtubeUrl: 'https://www.youtube.com/embed/rOECayR31fw'
                    // component: SitToStandAnimation
                },
                {
                    title: '<div>Decoupled Events and Tests</div>',
                    description: `<div><p>Prior to 5.3, a test had to be either a <i>baseline</i> test or an <i>event</i> test. Now, events are a separate entity representing a date range. Events have several properties:
                        <ul>
                            <li>Name</li>
                            <li>Start Date</li>
                            <li>End Date</li>
                            <li>Type</li>
                            <li>Notes</li>
                        </ul>
                    To learn more about this decoupling of events and tests, please watch the video above.
                    </p></div>`,
                    // component: SitToStandAnimation
                },
                {
                    title: 'Code Updates',
                    description: `<div
                    <p>Prior to 5.3, Group Codes were limited to only being <i>baseline assessments</i>. In 5.3, you can now create group codes that are <i>screenings</i> and also do not contribute to the profile's baselines.</p>
                    <p>We've also added the ability to show the results after a code. To show results at the end of a test taken through a code, check the "Show Results" checkbox at the bottom of the code creation page.  </p>
                    </div>`,
                    img: codeShowResults
                },
                
                {
                    title: 'Test Instructions Updates',
                    description: `<div>
                    <p>We have updated the test instructions user interface, and have added interactive practice tests to three of the cognitive tests:
                        <ul>
                            <li>Inspection Time</li>
                            <li>Memory</li>
                            <li>Cued Stroop</li>
                        </ul>
                    
                    </p>
                    </div>`,
                },
                {
                    title: 'Cued Stroop - Dark Theme',
                    description: `<div><p>The Cued Stroop test has been updated with a dark background to increase the contrast of the words and sqaures against the background.</p></div>`,
                    img: cuedStroopDarkTheme
                },
                {
                    title: 'CDC 30-Second Chair Stand',
                    description: `<div>
                    <p>The algorithm used to count the number of stands has been updated to count based on device <i>displacement</i> to increase the accuracy of the count. Previously it was based on the device <i>rotation</i>.</p>
                    </div>`,
                    // img: cuedStroopDarkTheme
                },
                {
                    title: 'Miscellaneous',
                    description: `<div>
                    <ul>
                        <li>Tests now have an optional "Name" field</li>
                    </ul>
                    </div>`,
                },
                // {
                //     title: 'New & Improved Dashboard',
                //     description: `Access what you need when you need it. Sway's new dashboard allows you to search profiles, see all open events, and all active codes in one spot. Search across all profiles and quickly access the most recent activity including athletes in current post-injury care.`,
                //     img: dashboard
                // },
                // {
                //     title: 'Easy Test Code Management',
                //     description: `Manage all your testing codes in one place. Codes can be created for a group or an individual, for either a multi-test baseline session or just a single event test. Test and session codes can be used in person or remote. In real-time, you will see each profile's level of consistency across all tests and protocols you assign.`,
                //     img: codeManagement
                // },
                // {
                //     title: 'Seamless Profile Management',
                //     description: `Sway's new profile screen displays all your athletes' information and objective data in one place. Profile creation has been simplified with additional data being collected. View the established baselines for the profile, see their trends over time, and view a history of their results. Easily and quickly administer a new test or session directly from the profile screen.`,
                //     img: profileManagement
                // },
                // {
                //     title: 'Profile Trends',
                //     description: `Quickly understand a profile's performance over time with individual charts for each test and protocol. See each profile's variability in their results and make educated decisions on an athlete's return to play when a sudden drop in the performance trend happens.`,
                //     img: trends
                // },
                // {
                //     title: 'New Error Detection',
                //     description: `Sway's testing is now even more reliable and consistent with error detection throughout the app. Error detection can now be identified immediately if a user pulls the phone away from the chest to take a peek at the device.  If an error is detected, the athlete will be notified in real-time and required to retake the test to get a more accurate baseline.`,
                //     img: errorDetection
                // },
            ]
        }
    }
}
</script>

<style scoped>
.section-title {
    font-weight: 500;
    margin: 20px 0;
    font-size: 16px;
    color: #333
}

.section-description {
    font-size: 14px;
    color: #757575
}
</style>