<template>
    <div>
        <div
            v-for="(section, sectionIndex) in sections"
            :key="sectionIndex"
            style="margin-bottom: 30px; margin-top: 20px"
        >


            <div class="section-title animate">
                {{section.title}}
            </div>
            <div class="section-description animate">
                {{section.description}}
            </div>
            <div class="animate" style="width: 100%; display: flex; justify-content: center; margin-top: 20px">
                <img :src="section.img" alt="" style="max-width: 80vw; max-height: 200px;">
            </div>
        </div>
    </div>
</template>

<script>
import baselining from "@/assets/images/versions/500/Baseline_V1.png";
import norms from "@/assets/images/versions/500/normative-data.png";
import report from "@/assets/images/versions/500/clinical-report.png";
import dashboard from "@/assets/images/versions/500/dashboard.png";
import codeManagement from "@/assets/images/versions/500/code-management.png";
import profileManagement from "@/assets/images/versions/500/profile-management.png";
import trends from "@/assets/images/versions/500/profile-trend.png";
import errorDetection from "@/assets/images/versions/500/error-detection.png";

export default {
    name: "Release500Sections",
    data() {
        return {
            sections: [
                {
                    title: 'Faster & More Efficient Baselining',
                    description: `Sway's baseline testing process just got faster and more efficient. We added a new confident baseline range, which means your athletes never take more tests than necessary to get an accurate baseline.`,
                    img: baselining
                },
                {
                    title: 'Extended Normative Data',
                    description: `One of our most significant new features is the addition of normative data to the profile results. View and click on the grey and dark grey bar that indicates the normative data range for the age and sex of each of your athletes.`,
                    img: norms
                },
                {
                    title: 'PDF Clinical Report',
                    description: `Sway's new clinical report makes it easy to securely share HIPAA compliant medical information with other clinicians in your network. We added the ability to download and share the password-protected report from your mobile device.`,
                    img: report
                },
                {
                    title: 'New & Improved Dashboard',
                    description: `Access what you need when you need it. Sway's new dashboard allows you to search profiles, see all open events, and all active codes in one spot. Search across all profiles and quickly access the most recent activity including athletes in current post-injury care.`,
                    img: dashboard
                },
                {
                    title: 'Easy Test Code Management',
                    description: `Manage all your testing codes in one place. Codes can be created for a group or an individual, for either a multi-test baseline session or just a single event test. Test and session codes can be used in person or remote. In real-time, you will see each profile's level of consistency across all tests and protocols you assign.`,
                    img: codeManagement
                },
                {
                    title: 'Seamless Profile Management',
                    description: `Sway's new profile screen displays all your athletes' information and objective data in one place. Profile creation has been simplified with additional data being collected. View the established baselines for the profile, see their trends over time, and view a history of their results. Easily and quickly administer a new test or session directly from the profile screen.`,
                    img: profileManagement
                },
                {
                    title: 'Profile Trends',
                    description: `Quickly understand a profile's performance over time with individual charts for each test and protocol. See each profile's variability in their results and make educated decisions on an athlete's return to play when a sudden drop in the performance trend happens.`,
                    img: trends
                },
                {
                    title: 'New Error Detection',
                    description: `Sway's testing is now even more reliable and consistent with error detection throughout the app. Error detection can now be identified immediately if a user pulls the phone away from the chest to take a peek at the device.  If an error is detected, the athlete will be notified in real-time and required to retake the test to get a more accurate baseline.`,
                    img: errorDetection
                },
            ]
        }
    }
}
</script>

<style scoped>
.section-title {
    font-weight: 500;
    margin: 20px 0;
    font-size: 16px;
    color: #333
}

.section-description {
    font-size: 14px;
    color: #757575
}
</style>