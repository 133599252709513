<template>
    <div style="display: flex; justify-content: center; background-color: white;  ">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <div style="max-height: 50px; margin: 20px" class="animate">
                <img :src="swayLogo" style="max-width: 100%; max-height: 30px">
            </div>
            <div class="header animate">
                <div>
                    Update to Version 5.0!
                </div>

                <div style="height: 2px; width: 100%; background-color: #34d3fd">
                    
                </div>
            </div>
            <div style="margin-top: 10px">
                <p>
                    Version 5.0 is available for download! Please update the app by visiting the either the Apple Store or Google Play Store.
                </p>
                
                
            </div>

            <div class="header animate" style="margin-top: 80px">
                <div>
                    5.0 Updates
                </div>

                <div style="height: 2px; width: 100%; background-color: #34d3fd">

                </div>
            </div>
            <Release500Sections />
            
        </div>

    </div>
</template>

<script>
import swayLogo from '@/assets/images/sway-logo.png'
import Release500Sections from "@/views/Versions/Release500Sections";

export default {
    name: "PleaseUpdate",
    components: {Release500Sections},
    data() {
        return {
            swayLogo,
        }
    }
}
</script>

<style scoped>

</style>