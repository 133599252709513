<template>
    <div >
        <router-view />

    </div>
</template>

<script>
import swayLogo from '@/assets/images/sway-logo.png'
import Release500Sections from "@/views/Versions/Release500Sections";

export default {
    name: "Release",
    components: {Release500Sections},
    data() {
        return {
            swayLogo,
            
        }
    },
    mounted() {
        // gsap.from('.animate', {
        //     y: 50,
        //     opacity: 0,
        //     stagger: 0.05
        // })
    }
}
</script>

<style>




.header {
    font-size: 20px;
    font-weight: 800;
    /*text-decoration: underline #34d3fd;*/
}
</style>