<template>
    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
        <div v-for="(vid,i) in videos" :key="i" style="display: flex; flex-direction: column; align-items: center; justify-content: center">
            <div style="margin-top: 20px">
                {{vid.label}}
            </div>

            <iframe height="150" :src="vid.url" style="margin-top: 10px; width: 80%; max-width: 300px"></iframe>
        </div>


    </div>
</template>

<script>

// reference https://mobilecontent.swaymedical.com/help/
import swayLogo from "@/assets/images/sway-logo.png";

export default {
    name: "Help",
    data() {
        return {
            swayLogo,
            videos: [
                {
                    label: 'Getting Started',
                    url: 'https://www.youtube.com/embed/YaRb5r34l94'
                },
                {
                    label: 'Add a Profile',
                    url: 'https://www.youtube.com/embed/8Z7z0IWtk'
                },
                {
                    label: 'Balance Testing',
                    url: 'https://www.youtube.com/embed/FsCkIIsP0e0'
                },
                {
                    label: 'Reaction Time Testing',
                    url: 'https://www.youtube.com/embed/RuTK4EbY8p0'
                },
                {
                    label: 'Establishing a Baseline',
                    url: 'https://www.youtube.com/embed/zz7IpQtDQzI'
                },
                {
                    label: 'Creating an Event',
                    url: 'https://www.youtube.com/embed/hKHVTS3nyI0'
                },
                {
                    label: 'Scoring System',
                    url: 'https://www.youtube.com/embed/f4nLQ6YyTb0'
                },
                // {
                //     label: 'Reviewing Results',
                //     url: 'https://www.youtube.com/embed/F2yC_CqvVxg'
                // },
                {
                    label: 'Profile Overview',
                    url: 'https://www.youtube.com/embed/MURRQyiXsUY'
                },
                {
                    label: 'Baseline Reset',
                    url: 'https://www.youtube.com/embed/s5emL4iTDNQ'
                },
                {
                    label: 'Creating a Code',
                    url: 'https://www.youtube.com/embed/S6rGbSWCzpY'
                },
                {
                    label: 'Performing a Baseline Session',
                    url: 'https://www.youtube.com/embed/e6iItEGhvNM'
                },
                {
                    label: 'Accelerometer Verification',
                    url: 'https://www.youtube.com/embed/vQMGKdBZbSk'
                },
                {
                    label: 'Inspection Time Testing',
                    url: 'https://www.youtube.com/embed/cCKbRf5RxlA'
                },
                {
                    label: 'Impulse Control Testing',
                    url: 'https://www.youtube.com/embed/NDH7CRP9pYY'
                },
                {
                    label: 'Memory Testing',
                    url: 'https://www.youtube.com/embed/tkDbL8iIH3A'
                },
                // {
                //     label: 'How To - Multiple Object Tracking Testing',
                //     url: 'https://www.youtube.com/embed/tgbNymZ7vqY'
                // },
                // {
                //     label: 'How To - Balance Testing',
                //     url: 'https://www.youtube.com/embed/tgbNymZ7vqY'
                // },
                // {
                //     label: 'how To - Reaction Time Testing',
                //     url: 'https://www.youtube.com/embed/tgbNymZ7vqY'
                // },
                // {
                //     label: 'How To - Impulse Control Testing',
                //     url: 'https://www.youtube.com/embed/tgbNymZ7vqY'
                // },
                // {
                //     label: 'How To - Inspection Time Testing',
                //     url: 'https://www.youtube.com/embed/tgbNymZ7vqY'
                // },
                {
                    label: 'At Home Testing',
                    url: 'https://www.youtube.com/embed/b449V9JOsJU'
                },
                {
                    label: 'Remote Symptom Tracking',
                    url: 'https://www.youtube.com/embed/94HilvbCNGs'
                },
                
            ]
        }
    }
}
</script>

<style scoped>

</style>