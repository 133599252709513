<template>
    <div style="display: flex; justify-content: center; background-color: white;  ">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <div style="max-height: 50px; margin: 20px" class="animate">
                <img :src="swayLogo" style="max-width: 100%; max-height: 50px">
            </div>
            <div class="header animate">
                <div style="text-align: center">
                    Update Available
                </div>

                <div style="height: 2px; width: 100%; background-color: #34d3fd">
                    
                </div>
            </div>
            <div style="margin-top: 20px">
                A new software version 5.2.2 is now available to download, please download immediately. <br /><br />Thanks for using Sway!
            </div>
            <!-- <Release520Sections /> -->
        </div>

    </div>
</template>

<script>
import swayLogo from '@/assets/images/sway-logo.png'
import Release500Sections from "@/views/Versions/Release500Sections";
import Release520Sections from "@/views/Versions/Release520Sections";

export default {
    name: "Release520Update",
    components: {Release520Sections, Release500Sections},
    data() {
        return {
            swayLogo,
            
        }
    },
    mounted() {
        // gsap.from('.animate', {
        //     y: 50,
        //     opacity: 0,
        //     stagger: 0.05
        // })
    }
}
</script>

<style>




.header {
    font-size: 18px;
    font-weight: 800;
    /*text-decoration: underline #34d3fd;*/
}
</style>